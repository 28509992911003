import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Form/Button/Button";
import Table from "components/Table/Table";
import Badge from "components/Badge/Badge";
import { getDate } from "utils/utils";

const Actions = ({ row, actions = [], readOnly = false }) => {
  const getClassByAction = (row, action, readOnly) => {
    const classList = ["btn-action"];

    switch (action.name) {
      case "readAll":
        break;
      case "read":
        if (row.status === "Draft") classList.push("hidden");
        break;
      case "edit":
        if (row.status !== "Draft") classList.push("hidden");
        break;
      case "review":
        if (row.status !== "Submitted") classList.push("disabled");
        break;
      case "delete":
        if (row.status !== "Draft") classList.push("disabled");
        break;
      default:
    }

    return classList.join(" ");
  };

  return (
    <div className="actions-wrapper">
      {actions.map(function (action, idx) {
        return (
          <Button
            key={idx}
            className={getClassByAction(row, action, readOnly)}
            title={action.title}
            stopPropagation={true}
            onClick={() => action.command(row.id)}
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

const SessionTable = ({
  data,
  actions,
  newAction,
  newActionLabel,
  readOnly = false,
  onRowClick,
}) => {
  const { t /*, i18n*/ } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        cssClass: "min-cell-width",
      },
      {
        Header: t("Description"),
        accessor: "description",
        cssClass: "font-medium w-[420px] min-w-[420] truncate",
      },
      {
        Header: t("Author"),
        accessor: "creator",
        cssClass: "whitespace-nowrap",
      },
      {
        Header: t("Status"),
        accessor: "status",
        cssClass: "w-[140px] min-w-[140px] text-center text-sm",
        Cell: ({ cell: { value } }) => (
          <Badge className="badge-status text-xs" value={value} />
        ),
      },
      {
        Header: t("Type"),
        accessor: "session_type",
        cssClass: "w-[140px] min-w-[140px] text-center text-xs",
        Cell: ({ cell: { value } }) => <Badge value={value} />,
      },
      {
        Header: t("Class ID"),
        accessor: "class_id",
        cssClass: "text-center min-cell-width",
        Cell: ({ cell: { value } }) => (value ? value : "-"),
      },
      {
        Header: t("Class author"),
        accessor: "class_creator",
        Cell: ({ cell: { value } }) => (value ? value : "-"),
      },
      {
        Header: t("Date created"),
        accessor: "created_at",
        cssClass: "text-center min-cell-width",
        Cell: ({ cell: { value } }) => getDate(value),
      },
      {
        Header: t("Grade"),
        accessor: "grade",
        cssClass: "text-center min-cell-width text-xs",
        Cell: ({ cell: { value } }) =>
          value ? <Badge className="badge-status" value={value} /> : "-",
      },
      /*
      {
        Header: t("Reviewer"),
        accessor: "reviewer",
      },
      {
        Header: t("Date graded"),
        accessor: "reviewed_at",
        cssClass: "text-center min-cell-width",
        Cell: ({ cell: { value } }) => getDate(value),
      },
      */
      {
        Header: t("Actions"),
        accessor: "accessor",
        cssClass: "text-center min-cell-width pr-5",
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <Actions row={original} actions={actions} readOnly={readOnly} />
        ),
      },
    ],
    [actions, readOnly, t],
  );

  return (
    <>
      <Table
        className="bg-white"
        columns={columns}
        data={data}
        searchLabel={t("Search by description, author or class ID")}
        searchColumns={["description", "creator", "class_id"]}
        newAction={readOnly === true ? "" : newAction}
        newActionLabel={newActionLabel}
        onRowClick={onRowClick}
      />
      {!data.length && (
        <p className="mt-8 text-sm text-gray-400 text-center">
          {t("No sessions yet")}
        </p>
      )}
    </>
  );
};

export default SessionTable;
