import React, { useCallback, useState } from "react";
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table";
import { useTranslation } from "react-i18next";

import Input from "components/Form/Input/Input";
import Button from "components/Form/Button/Button";

import "./Table.scss";

export const Actions = ({ row, actions = [] }) => {
  return (
    <div className="actions-wrapper">
      {actions.map(function (action, idx) {
        return (
          <Button
            key={idx}
            className="btn-action"
            stopPropagation={true}
            onClick={() => action.command(row)}
            title={action.title}
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

const Table = ({
  columns,
  data,
  className,
  noDataMessage = "Table is empty",
  searchLabel = "Search",
  searchColumns = null,
  searchFilter = null,
  searchGlobal = false,
  newAction = "",
  newActionLabel = null,
  initialState,
  showSearch = true,
  onRowClick,
}) => {
  const [filterInput, setFilterInput] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation();

  const classes = ["table"];
  if (className) classes.push(className);

  /**
   * define a customFilter function to include only specific columns
   * if no searchColumns defined, it will do a global search
   * using useCallback to cache the function definition between re-renders
   */
  const customFilter = useCallback(
    (rows, columns, query) => {
      return rows.filter((row) => {
        return searchColumns
          ? searchColumns.some((column) => {
              return row.values[column]
                ?.toString()
                .toLowerCase()
                .includes(query.toLowerCase());
            })
          : Object.values(row.values).some((cell) => {
              return cell
                ?.toString()
                .toLowerCase()
                .includes(query.toLowerCase());
            });
      });
    },
    [searchColumns],
  );

  // Update the state when input changes
  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilterInput(value);
    if (searchColumns) {
      if (searchColumns.length === 1) setFilter(searchColumns[0], value);
      if (searchColumns?.length > 1) setGlobalFilter(value);
    } else setGlobalFilter(value);
  };

  const handleRowClick = (event, row) => {
    if (onRowClick) {
      setSelectedRow(row.id);
      onRowClick(row);
    }
  };

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setFilter, // The useFilter Hook provides a way to set the filter
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState,
      globalFilter: searchFilter ? searchFilter : customFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  );

  return (
    <div className="overflow-hidden flex flex-col gap-y-2">
      {(showSearch || newAction) && (
        <div className="search-wrapper p-1 flex items-center">
          {showSearch && (
            <Input
              className="w-96"
              label={t(searchLabel)}
              value={filterInput || ""}
              onChange={handleFilterChange}
            />
          )}
          {newAction && (
            <Button
              className={`btn-primary m-0 ml-auto ${
                newAction ? "" : "disabled"
              }`}
              onClick={newAction}
            >
              <i className="ri-add-line font-black text-xl -my-2"></i>
              {newActionLabel ? t(newActionLabel) : t("Add new")}
            </Button>
          )}
        </div>
      )}

      <div className="table-wrapper overflow-scroll">
        <table className={classes.join(" ")} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className="border-b text-left"
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={
                      column.getHeaderProps(column.getSortByToggleProps()).key
                    }
                    title={
                      column.canSort
                        ? `${t("Sort by")} ${t(column?.Header).toLowerCase()}`
                        : ""
                    }
                    className={
                      (column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc "
                          : "sort-asc "
                        : "") + column.cssClass
                    }
                  >
                    {t(column?.Header)}
                    {column.canSort ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="ri-arrow-up-s-line"></i>
                        ) : (
                          <i className="ri-arrow-down-s-line"></i>
                        )
                      ) : (
                        <i className="ri-expand-up-down-line"></i>
                      )
                    ) : (
                      ""
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={row.getRowProps().key}
                  className={`border-b text-left ${
                    row.id === selectedRow ? "selected " : ""
                  }${onRowClick ? "selectable" : ""}`}
                  onClick={(e) => handleRowClick(e, row)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                        className={cell.column.cssClass}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {rows.length === 0 && (
          <div className="pt-8 pb-4 flex justify-center items-center text-sm text-slate-500">
            {t(noDataMessage)}
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
