import axiosInstance from "api/axiosInstance";

/**
 *  GET /grade
 *
 *  get all grades
 */
export const getGrades = async () => {
	return await axiosInstance
		.get("/grade")
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /grade/{grade_id}
 *
 *  get a grade by id
 */
export const getGrade = async (id) => {
	return await axiosInstance
		.get(`/grade/${id}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
