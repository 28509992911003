import { useEffect, useState, useRef, useId } from "react";

const HelpTooltip = ({ className, text, ...props }) => {
	const id = useId();
	const buttonRef = useRef(null);
	const tooltipRef = useRef(null);
	const [isHovered, setIsHovered] = useState(false);

	const classes = [
		"help-wrapper relative z-10 inline-flex items-center text-gray-400 hover:text-gray-500",
	];
	if (className) classes.push(className);

	// calculate tooltip position
	const positionTooltip = () => {
		if (tooltipRef.current && buttonRef.current) {
			const buttonRect = buttonRef.current.getBoundingClientRect();
			const tooltipRect = tooltipRef.current.getBoundingClientRect();
			const viewportWidth = window.innerWidth;
			let left = 0;

			// adjust position based on available space
			if (buttonRect.left + tooltipRect.width > viewportWidth) {
				left = -tooltipRect.width - 10;
			} else {
				left = buttonRect.width + 10;
			}

			tooltipRef.current.style.left = `${left}px`;
		}
	};

	// calculate position on initial render
	useEffect(() => {
		positionTooltip();
		window.addEventListener("resize", positionTooltip);
	}, []);

	useEffect(() => {
		positionTooltip();
	}, [isHovered]);

	return (
		<button className={classes.join(" ")} {...props}>
			<i
				className="ri-question-line absolute text-xl cursor-help peer"
				ref={buttonRef}
				aria-describedby={id}
				tabIndex="0"
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			></i>
			<div
				id={id}
				className={`message__note w-[600px] absolute z-50 top-[10px] text-left shadow-md pointer-events-none opacity-0 peer-hover:opacity-100 `}
				ref={tooltipRef}
				role="tooltip"
				aria-hidden={!isHovered}
			>
				<i className="ri-book-open-line"></i>
				<div>
					{text.split("\n").map((line, index) => (
						<p key={index}>{line}</p>
					))}
				</div>
			</div>
		</button>
	);
};

export default HelpTooltip;
