import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getClassSessions } from "api/sessions";
import { getTreatmentPlans } from "api/treatmentPlans";
import { getStory } from "api/stories";

import CaseTabs from "components/Tabs/CaseTabs";

const ReviewStory = () => {
	const { classId, storyId } = useParams();
	const [story, setStory] = useState({});
	const [classSessions, setClassSessions] = useState([]);
	const [TPs, setTPs] = useState([]);
	const [refreshClassSessionList, setRefreshClassSessionList] = useState(true);

	/**
	 * 3.
	 * get the story using the storyId from the URL
	 */

	useEffect(() => {
		const fetchStory = async () => {
			try {
				const response = await getStory(storyId);
				if (response.code === "ERR_BAD_REQUEST") {
					console.error(response);
				} else {
					setStory(response);
				}
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchStory();
	}, [storyId]);

	/**
	 * 2.
	 * get all the TPs using the storyId from the submitted sessions
	 * and save them in the TPs state
	 */

	useEffect(() => {
		if (!classSessions) return;

		const getTPsFromSession = async (sessionId, storyId) => {
			try {
				const response = await getTreatmentPlans(sessionId);

				if (response.code === "ERR_BAD_REQUEST") {
					console.error(response);
				} else {
					console.log(
						`Getting TP for session ${sessionId} and story ${storyId}`,
					);
					return response.find((tp) => tp.story.id === Number(storyId));
				}
			} catch (error) {
				console.error("An error occurred:", error);
				return [];
			}
		};

		setTPs([]);

		classSessions.forEach(async (session) => {
			const tp = await getTPsFromSession(session.id, storyId);
			console.log("TP", tp);
			setTPs((prev) => [...prev, { ...tp, creator: session.creator }]);
		});
	}, [classSessions, storyId]);

	/**
	 * 1.
	 * get all the submitted sessions for the class
	 * and save them in the classSessions state
	 */

	useEffect(() => {
		if (!refreshClassSessionList) return;

		const fetchSessions = async () => {
			try {
				const response = await getClassSessions(classId);
				setClassSessions(response);
				console.log("fetched sessions", response);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshClassSessionList(false);
			}
		};

		fetchSessions();
	}, [classId, refreshClassSessionList]);

	return (
		<>
			<div className="review h-full flex gap-4 items-stretch">
				<div className="view w-full">
					<h1>Rewiew Case</h1>
					<p>
						Reviewing all the TreatmentPlans that include{" "}
						<u>storyId {storyId}</u> from all the submitted sessions for the
						class <u>classId {classId}</u>.
					</p>

					<div className="py-6 text-sm">
						<h3 className="mb-4">{story.title}</h3>
						<p className="max-w-3xl">{story.description}</p>
					</div>

					<div className="sessions-list">
						<h3>List of Treatment plans</h3>
						<CaseTabs
							cases={TPs}
							onShowReviewBox={() => {}}
							onShowDeleteCaseModal={() => {}}
						></CaseTabs>
					</div>
				</div>
			</div>
		</>
	);
};

ReviewStory.displayName = "ReviewStory";

export default ReviewStory;
