import axiosInstance from "api/axiosInstance";

/**
 *  GET /difficulty
 *
 *  get all difficulty levels
 */
export const getDifficulties = async () => {
	return await axiosInstance
		.get("/difficulty")
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /difficulty/{difficulty_id}
 *
 *  get a difficulty level by id
 */
export const getDifficulty = async (id) => {
	return await axiosInstance
		.get(`/difficulty/${id}`)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /difficulty
 *
 *  create a new difficulty level
 */
export const createDifficulty = async ({ name, description }) => {
	const data = {
		name,
		description,
	};

	try {
		const response = await axiosInstance.post("/difficulty", data);
		return response;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /difficulty/{difficulty_id}
 *
 *  update a difficulty level
 */
export const updateDifficulty = async ({ id, name, description }) => {
	const data = {
		name,
		description,
	};

	try {
		const response = await axiosInstance.put(`/difficulty/${id}`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /difficulty/{difficulty_id}
 *
 *  delete a difficulty level
 */
export const deleteDifficulty = async (id) => {
	try {
		const response = await axiosInstance.delete(`/difficulty/${id}`);
		return response;
	} catch (error) {
		throw error;
	}
};
