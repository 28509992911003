import { createContext, useContext, useState, useEffect } from "react";

import { parseJwt } from "utils/utils";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authData, _setAuthData] = useState(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  const isAuthenticated =
    authData &&
    parseJwt(authData.access_token)?.exp > Math.floor(Date.now() / 1000);

  const setAuthData = (token) => {
    _setAuthData(token);
  };

  const clearAuthData = () => {
    _setAuthData(null);
    localStorage.removeItem("authData");
  };

  // Load auth data from local storage on mount

  useEffect(() => {
    const savedAuthData = JSON.parse(localStorage.getItem("authData"));

    console.log("loading auth data on mount", savedAuthData);

    const isValid =
      savedAuthData &&
      parseJwt(savedAuthData.access_token)?.exp > Math.floor(Date.now() / 1000);

    if (isValid) {
      setAuthData(savedAuthData);
    } else {
      console.log("not valid, logging out");
      clearAuthData();
    }

    setIsAuthLoading(false);
  }, []);

  // Save auth data to local storage whenever it changes

  useEffect(() => {
    if (authData) {
      localStorage.setItem("authData", JSON.stringify(authData));

      // Automatic logout on token expiration

      const { exp } = parseJwt(authData.access_token);
      const timeout = exp * 1000 - Date.now();
      if (timeout > 0) {
        const timer = setTimeout(clearAuthData, timeout);
        return () => clearTimeout(timer);
      }
    } else {
      localStorage.removeItem("authData");
    }
  }, [authData]);

  return (
    <AuthContext.Provider
      value={{
        authData,
        setAuthData,
        clearAuthData,
        isAuthLoading,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export { AuthProvider, useAuth };
