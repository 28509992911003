import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Badge from "components/Badge/Badge";

import { getPatients } from "api/patients";

const SelectStoryModal = (props) => {
	const [patient /*, setPatient*/] = useState(null);
	const { t } = useTranslation();
	const historyBadges = [];

	if (patient?.patient_history)
		patient["patient_history"].forEach((item, idx) => {
			if (item.description)
				historyBadges.push(
					<Badge key={idx} className="bg-slate-400" value={item.description} />,
				);
		});
	if (historyBadges.length === 0) historyBadges.push("-");

	// table - states
	const [refreshTable, setRefreshTable] = useState(false);

	/*
	const handleRowClick = async (row) => {
		setPatient(row.original);

		const data = await getPatient(row.original.id);

		if (data === "ERR_BAD_REQUEST") {
			toast.error(t(data.response.response.data));
			console.log(data);
		} else {
			setPatient(data);
		}
	};
	*/

	/*
	const handleSubmit = () => {
		props.onSubmit(patient);
		setPatient("");
		props.onClose();
	};
	*/

	useEffect(() => {
		(async () => {
			const tableData = await getPatients();

			if (tableData === "ERR_BAD_REQUEST") {
				// alert("Cannot get sessions: ERR_BAD_REQUEST");
				// setShowSessionExpiredModal(true);
			} else {
				setRefreshTable(false);
			}
		})();
	}, [refreshTable]);

	return (
		<Modal
			title={t("Select story")}
			isOpen={props.isOpen}
			onClose={props.onClose}
			onSubmit={props.onSubmit}
			className="select-story-modal w-3/5 h-4/5"
		>
			to be implemented
		</Modal>
	);
};

export default SelectStoryModal;
