import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";
import CaseTabs from "components/Tabs/CaseTabs";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import ReviewForm from "components/ReviewForm/ReviewForm";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import NewHomeSessionModal from "components/NewHomeSessionModal/NewHomeSessionModal";
import SelectStoryModal from "components/SelectStoryModal/SelectStoryModal";
import SelectPatientModal from "components/SelectPatientModal/SelectPatientModal";

import { getSession, updateSession, deleteSession } from "api/sessions";
import {
	getCases,
	getCase,
	createCase,
	updateCase,
	deleteCase,
} from "api/cases";
import { reviewSession } from "api/reviews";
import { reviewTreatmentPlan } from "api/reviews";

import { useUser } from "context/UserContext";

import { getDate, isStudent, isTeacher } from "utils/utils";
import { getHelpText } from "utils/helpText";

const SingleSession = ({ readOnly = false, showReview = false }) => {
	const { sessionId } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { userData, canI, amI } = useUser();

	const [session, setSession] = useState([]);
	const [refreshSession, setRefreshSession] = useState(true);

	// selected tab index
	const [selectedIndex, setSelectedIndex] = useState(0);

	const [cases, setCases] = useState([]);
	const [currentCase, setCurrentCase] = useState({});
	const [refreshCase, setRefreshCase] = useState(false);
	const [refreshCases, setRefreshCases] = useState(true);

	const [showEditTitleModal, setShowEditTitleModal] = useState(false);
	const [showDeleteSessionModal, setShowDeleteSessionModal] = useState(false);
	const [showSubmitSessionModal, setShowSubmitSessionModal] = useState(false);
	const [showSelectStoryModal, setShowSelectStoryModal] = useState(false);
	const [showSelectPatientModal, setShowSelectPatientModal] = useState(false);
	const [showReviewBox, setShowReviewBox] = useState(false);
	const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false);

	/**
	 * SESSION HANDLERS
	 */

	const handleUpdateTitle = async (description) => {
		try {
			const response = await updateSession({
				sessionId,
				description,
				classId: 0,
				statusId: 1,
			});
			if (response.status === 200 || response.status === 201) {
				setSession({ ...session, description: description });
				toast.success(t("Session name updated!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowEditTitleModal(false);
		}
	};

	const handleDeleteSession = async () => {
		try {
			const response = await deleteSession(sessionId);
			if (response.status === 200 || response.status === 201) {
				toast.success(t("Session deleted!"));
				navigate("/sessions");
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowDeleteSessionModal(false);
		}
	};

	const handleSubmitSession = async () => {
		try {
			const response = await updateSession({
				sessionId: session.id,
				description: session.description,
				classId: session.class_id,
				statusId: 2,
			});

			if (response.status === 200 || response.status === 201) {
				// setSession({ ...session, status: "Submitted" });
				setRefreshSession(true);
				toast.success(t("Session submitted!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowSubmitSessionModal(false);
		}
	};

	/**
	 * CASETABS HANDLERS
	 */

	const handleAddTab = () => {
		const draftCase = {
			id: 0,
			patient: null,
			story: null,
		};

		setSelectedIndex(cases.length + 1);
		setCurrentCase(draftCase);
		setCases([...cases, draftCase]);
	};

	const handleRemoveTab = () => {
		// remove last case if it is a draft case and select the one before the last
		if (cases[cases.length - 1].id === 0) {
			setSelectedIndex(cases.length - 2);
			setCurrentCase(cases[cases.length - 2]);
			setCases(cases.slice(0, -1));
		}
	};

	const handleChangeTab = (index) => {
		setSelectedIndex(index);

		// index 0 is the overview
		if (index > 0 && cases[index - 1]) {
			setCurrentCase(cases[index - 1]);
		}
	};

	/**
	 * CASE HANDLERS
	 */

	const handleDeleteCase = async () => {
		try {
			const response = await deleteCase(currentCase.id);

			if (response.status === 200 || response.status === 201) {
				setRefreshCases(true);
				setSelectedIndex((prev) => (prev - 1 >= 0 ? prev - 1 : 0));
				toast.success(t("Case deleted!"));
				/*
				if (selectedIndex > 0) {
					setPatient(completeTreatmentPlans[selectedIndex - 1].patient);
					setPrescriptions(
						completeTreatmentPlans[selectedIndex - 1].prescriptions,
					);
					setTreatmentPlanId(completeTreatmentPlans[selectedIndex - 1].id);
					setSelectedIndex((prev) => prev - 1);
				}
				*/
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			const errorMessage = error.response?.data?.detail || "An error occurred";
			toast.error(t(errorMessage));
			console.error("An error occurred:", error);
		} finally {
			setShowDeleteCaseModal(false);
		}
	};

	/**
	 * STORY HANDLERS
	 */

	const handleSubmitStory = async (story) => {
		/*
		try {
			const response = await updateCase({
				id: currentCase.id,
				storyId: story.story.id,
			});

			if (response.status === 200 || response.status === 201) {
				// udate the current case
				setCurrentCase({
					...currentCase,
					story_id: story.story.id,
					story: story.story,
				});

				// update all the cases
				setCases((prevCases) =>
					prevCases.map((c) =>
						c.id === currentCase.id
							? {
									...currentCase,
									story_id: story.story.id,
									story: story.story,
							  }
							: c,
					),
				);

				toast.success(t("Story saved!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			const errorMessage = error.response?.data?.detail || "An error occurred";
			toast.error(t(errorMessage));
			console.error("An error occurred:", error);
		}
		*/
	};

	/**
	 * PATIENT HANDLERS
	 */

	const handleSubmitPatient = async (patient) => {
		try {
			const response = currentCase.id
				? await updateCase({
						id: currentCase?.id,
						patientId: patient.patient.id,
				  })
				: await createCase({
						sessionId: sessionId,
						patientId: patient.patient.id,
				  });

			if (response.status !== 200 && response.status !== 201) {
				console.error("Unexpected response:", response);
			} else {
				// update the current case
				setCurrentCase({
					...currentCase,
					patient_id: patient.patient.id,
					patient: patient.patient,
				});

				// update all the cases
				/*
				setCases((prevCases) =>
					prevCases.map((c) =>
						c.id === currentCase.id
							? {
									...currentCase,
									patient_id: patient.patient.id,
									patient: patient.patient,
							  }
							: c,
					),
				);
				*/

				setRefreshCases(true);
				toast.success(t("Patient saved!"));
			}
		} catch (error) {
			const errorMessage = error.response?.data?.detail || "An error occurred";
			toast.error(t(errorMessage));
			console.error("An error occurred:", error);
		}
	};

	/**
	 * REVIEW HANDLERS
	 */

	const handleSubmitReviewSessionForm = async ({ grade, notes }) => {
		try {
			const response = await reviewSession({
				id: sessionId,
				gradeId: grade.value,
				notes: notes,
			});

			if (response?.status === 200 || response.status === 201) {
				setRefreshSession(true);
				toast.success(t("Session review saved!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const handleSubmitReviewCaseForm = async ({ grade, notes }) => {
		console.log("reviewing TP...");
		try {
			const response = await reviewTreatmentPlan({
				id: currentCase.id,
				gradeId: grade.value,
				notes: notes,
			});

			if (response?.status === 200 || response.status === 201) {
				setRefreshCase(true);
				toast.success(t("Treatment plan review saved!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	/*
	const handleSubmitReview = async () => {
		console.log("submitting review...");
		const response = await submitReviewedSession(sessionId);
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else if (response.status === 200 || response.status === 201) {
			toast.success(t("Review submitted!"));
			setRefreshSession(true);
			setShowSubmitReviewModal(false);
		}
	};
	*/

	// load the session

	useEffect(() => {
		if (!refreshSession) return;

		const loadSession = async () => {
			try {
				const data = await getSession(sessionId);
				setSession(data);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshSession(false);
			}
		};

		loadSession();
	}, [sessionId, refreshSession]);

	// load all cases

	useEffect(() => {
		if (!refreshCases) return;

		const loadCases = async () => {
			try {
				const data = await getCases(sessionId);
				if (data && data.length > 0) {
					setCases(data.reverse());

					// set the current case based on the selected tab
					setCurrentCase(data[selectedIndex]);

					// setPatientId(data[selectedIndex]?.patient.id);
					// setPrescriptions(data[selectedIndex]?.prescriptions);
				}
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshCases(false);
			}
		};

		loadCases();
	}, [sessionId, selectedIndex, refreshCases]);

	// refresh a case

	useEffect(() => {
		if (!refreshCase) return;

		console.log("refreshing the case", currentCase.id);

		const loadCase = async () => {
			try {
				const response = await getCase(currentCase.id);
				if (response && response.length > 0) {
					// refresh the case in the cases array
					setCases((prevCases) =>
						prevCases.map((c) =>
							c.id === currentCase.id ? { ...currentCase, ...response } : c,
						),
					);
				}
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshCase(false);
			}
		};

		loadCase();
	}, [currentCase, refreshCase]);

	return (
		<>
			<div className="single-session h-full flex gap-4 items-stretch">
				<div className="view">
					<div className="local-actions -ml-1 flex items-center text-gray-500">
						<Button
							className="h-8 w-6 p-0 mr-2 btn-action font-light text-2xl text-inherit"
							title={t("Back")}
							onClick={() => navigate(-1)}
						>
							<i className="ri-arrow-left-line"></i>
						</Button>
						<div className="flex text-sm">
							{session.status === "Draft" && (
								<Button
									className="inline-action inline-flex gap-1 items-center"
									title={t("Edit session name")}
									onClick={() => setShowEditTitleModal(true)}
								>
									<i className="ri-pencil-line"></i>
									{t("Edit session name")}
								</Button>
							)}
							{isStudent(userData) && session.status === "Draft" && (
								<Button
									className="inline-action inline-flex gap-1 items-center"
									onClick={() => setShowSubmitSessionModal(true)}
								>
									<i className="ri-mail-send-line"></i>
									{session.session_type === "Self-Study"
										? t("Request feedback")
										: t("Submit session")}
								</Button>
							)}
							{isStudent(userData) && session.status === "Submitted" && (
								<div className="pl-1 pr-4">{t("No actions available")}</div>
							)}
							{isTeacher(userData) && (
								<Button
									className="inline-action inline-flex gap-1 items-center"
									onClick={() => setShowReviewBox(true)}
								>
									<i className="ri-file-check-line"></i>
									{t("Review session")}
								</Button>
							)}
							{isTeacher(userData) && (
								<Button
									className="inline-action inline-flex gap-1 items-center"
									onClick={() => alert("Send feedback!")}
								>
									<i className="ri-mail-send-line"></i>
									{t("Send feedback")}
								</Button>
							)}
							{canI("delete", "SingleSession") &&
								session.status === "Draft" && (
									<Button
										className="inline-action danger inline-flex gap-1 items-center"
										onClick={() => setShowDeleteSessionModal(true)}
									>
										<i className="ri-delete-bin-line"></i>
										{t("Delete session")}
									</Button>
								)}
							<HelpTooltip text={t(getHelpText(session.status))} />
						</div>
					</div>
					<div className="header ml-8 pb-2 border-b">
						<span className="font-light text-xl">[{t("Session")}]</span>
						<h1>{session.description}</h1>
						<Badge className="ms-2 -mt-[2px] text-xxs" value={session.status} />
						<div className="meta ml-auto -mb-[3px] text-sm self-end flex gap-6 text-gray-500">
							<div title={t("Session ID")}>
								{t("Session ID")} {session.id}
							</div>
							{session.session_type === "Self-Study" ? (
								<div title={t("Session type")}>{t("Self-Study")}</div>
							) : (
								<>
									<div title={t("Class ID")}>
										{`${t("Class ID")} ${session.class_id}`}
									</div>
									<div title={t("Class author")}>
										<i className="ri-account-circle-line"></i>{" "}
										{session.class_creator}
									</div>
								</>
							)}
							{amI("Teacher") && (
								<div title={t("Author")}>
									<i className="ri-account-circle-line"></i> {session.creator}
								</div>
							)}
							<div title={t("Session created on")}>
								<i className="ri-calendar-line"></i>{" "}
								{getDate(session.created_at)}
							</div>
						</div>
					</div>

					<div className="content ms-8 mt-2 max-w-screen-xl">
						<CaseTabs
							className="pt-2"
							cases={cases}
							selectedIndex={selectedIndex}
							allowAdd={session.session_type === "Self-Study"}
							onAddTab={handleAddTab}
							onRemoveTab={handleRemoveTab}
							onChange={handleChangeTab}
							onShowDeleteCaseModal={() => setShowDeleteCaseModal(true)}
							onOpenStoryModal={() => setShowSelectStoryModal(true)}
							onOpenPatientModal={() => setShowSelectPatientModal(true)}
						/>
					</div>
				</div>

				{(showReviewBox || showReview) && (
					<aside className="aside pt-[172px] ml-6">
						<div className="p-4 border rounded bg-slate-100 flex flex-col gap-8">
							{selectedIndex === 0 && (
								<>
									<div className="pb-2 border-b flex gap-2 items-center">
										<h3>{t("Review session")}</h3>
										<HelpTooltip
											text={t(
												"Review the session as a whole.\nYou can see the overview of all cases and their feedback on the first tab.",
											)}
										/>
									</div>
									<ReviewForm
										target={session}
										readOnly={!canI("review", "SingleSession")}
										buttonCaption={t("Review session")}
										onClose={() => setRefreshSession(true)}
										onSubmit={handleSubmitReviewSessionForm}
									/>
								</>
							)}
							{selectedIndex > 0 && (
								<>
									<div className="pb-2 border-b flex gap-2 items-center">
										<h3>{`${t("Review case")} ${
											cases[selectedIndex - 1]?.id
										}`}</h3>
										<HelpTooltip
											text={t(
												"Review single cases.\nYou can see the overview of all cases and their feedback on the first tab.",
											)}
										/>
									</div>
									<ReviewForm
										target={currentCase}
										readOnly={!canI("review", "SingleSession")}
										buttonCaption={t("Review treatment plan")}
										onClose={() => setRefreshCase(true)}
										onSubmit={handleSubmitReviewCaseForm}
									/>
								</>
							)}
						</div>
					</aside>
				)}
			</div>

			{showEditTitleModal && (
				<NewHomeSessionModal
					description={session.description}
					onClose={() => setShowEditTitleModal(false)}
					onSubmit={(description) => handleUpdateTitle(description)}
				/>
			)}

			{showDeleteSessionModal && (
				<ConfirmationModal
					title={t("Delete session?")}
					message={`${t(
						"Are you sure you want to delete the session with the ID",
					)} ${sessionId}? ${t("This operation cannot be undone.")}`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeleteSessionModal(false)}
					onSubmit={handleDeleteSession}
				/>
			)}

			{showSubmitSessionModal && (
				<ConfirmationModal
					title={
						session.session_type === "Self-Study"
							? t("Request feedback")
							: t("Confirm submit")
					}
					message={t(
						"You are going to ask for feedback. After that, changes in this session are not possible anymore. Are you sure the session is ready for feedback?",
					)}
					yesMessage={t("Submit")}
					onClose={() => setShowSubmitSessionModal(false)}
					onSubmit={handleSubmitSession}
				/>
			)}

			{showDeleteCaseModal && (
				<ConfirmationModal
					title={t("Delete treatment plan?")}
					message={`${t(
						"Are you sure you want to delete the treatment plan with the ID",
					)} ${currentCase.id}?`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeleteCaseModal(false)}
					onSubmit={handleDeleteCase}
				/>
			)}

			{showSelectStoryModal && (
				<SelectStoryModal
					onClose={() => setShowSelectStoryModal(false)}
					onSubmit={handleSubmitStory}
				/>
			)}

			{showSelectPatientModal && (
				<SelectPatientModal
					onClose={() => setShowSelectPatientModal(false)}
					onSubmit={handleSubmitPatient}
				/>
			)}
		</>
	);
};

SingleSession.displayName = "SingleSession";

export default SingleSession;
