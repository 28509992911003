import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";

import { useUser } from "context/UserContext";

import "./Dashboard.scss";

const Dashboard = () => {
	const { t } = useTranslation();
	const { canI } = useUser();

	return (
		<>
			<div className="dashboard view">
				<div className="header">
					<h1>{t("Dashboard")}</h1>
					<HelpTooltip
						text={t(
							"This is a placeholder for the Dashboard where in future releases stats and quick actions could be displayed. The content will also be adjusted accordingly to the user's persmissions.",
						)}
					/>
				</div>
				<div className="content">
					<h2>{t("Quick links")}</h2>
					<div className="p-1 my-5 flex gap-4">
						{canI("view", "Classes") && (
							<NavLink
								to="/classes"
								className="quicklink flex items-center p-6 gap-4"
							>
								<i className="ri-school-line"></i>
								<span>{t("Manage classes")}</span>
							</NavLink>
						)}
						{canI("view", "Stories") && (
							<NavLink
								to="/stories"
								className="quicklink flex items-center p-6 gap-4"
							>
								<i className="ri-file-list-3-line"></i>
								<span>{t("Manage stories")}</span>
							</NavLink>
						)}
						{canI("view", "Patients") && (
							<NavLink
								to="/patients"
								className="quicklink flex items-center p-6 gap-4"
							>
								<i className="ri-folder-user-line"></i>
								<span>{t("Manage patients")}</span>
							</NavLink>
						)}
						{canI("view", "Users") && (
							<NavLink
								to="/users"
								className="quicklink flex items-center p-6 gap-4"
							>
								<i className="ri-folder-user-line"></i>
								<span>{t("Manage users")}</span>
							</NavLink>
						)}
						{canI("view", "Sessions") && (
							<NavLink
								to="/sessions"
								className="quicklink flex items-center p-6 gap-4"
							>
								<i className="ri-folder-user-line"></i>
								<span>
									{canI("create", "SingleSession")
										? t("My sessions")
										: t("Sessions")}
								</span>
							</NavLink>
						)}
					</div>
					<p className="mt-auto text-sm">
						&copy; {new Date().getFullYear()} <strong>edups</strong> -
						Educational prescribing system. {t("For support send an e-mail to")}{" "}
						<a href="mailto:">support@edups.nl</a>
					</p>
				</div>
			</div>
		</>
	);
};

Dashboard.displayName = "Dashboard";

export default Dashboard;
