import { useTranslation } from "react-i18next";

import Button from "components/Form/Button/Button";

import { useUser } from "context/UserContext";

const caseStatus = {
  DRAFT: 1,
  SUBMITTED: 2,
  REVIEWED: 3,
};

const TabHeader = ({
  id = 0,
  statusId,
  onClick,
  onShowReviewBox,
  onShowDeleteCaseModal,
}) => {
  const { canI } = useUser();
  const { t } = useTranslation();

  return (
    <div className="header pb-2 border-b text-sm">
      <div className="local-actions -ml-1 flex items-center text-gray-500">
        {canI("review", "SingleSession") && (
          <Button
            className="inline-action mr-0 inline-flex gap-1 items-center"
            onClick={onShowReviewBox}
          >
            <i className="ri-file-check-line text-xs"></i>
            <span>{t("Review treatment plan")}</span>
          </Button>
        )}
        {statusId === caseStatus.DRAFT && (
          <Button
            className="inline-action mr-0 inline-flex gap-1 items-center"
            onClick={onShowDeleteCaseModal}
          >
            <i className="ri-delete-bin-line"></i>
            <span>{t("Delete treatment plan")}</span>
          </Button>
        )}
      </div>
      <div className="meta ml-auto -mb-[3px] self-end flex gap-6 text-gray-500">
        <span className="leading-normal" title={t("Case ID")}>
          {!id ? t("Draft") : [t("Case ID"), id].join(" ")}
        </span>
      </div>
    </div>
  );
};

export default TabHeader;
