import axiosInstance from "api/axiosInstance";

/**
 *  GET /categories
 *
 *  get all categories
 */
export const getCategories = async () => {
	return await axiosInstance
		.get("/category")
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /category/{category_id}
 *
 *  get a category level by id
 */
export const getCategory = async (id) => {
	return await axiosInstance
		.get(`/category/${id}`)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /category
 *
 *  create a new category level
 */
export const createCategory = async ({ name, description }) => {
	const data = {
		name,
		description,
	};

	try {
		const response = await axiosInstance.post("/category", data);
		return response;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /category/{category_id}
 *
 *  update a category level
 */
export const updateCategory = async ({ id, name, description }) => {
	const data = {
		name,
		description,
	};

	try {
		const response = await axiosInstance.put(`/category/${id}`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /category/{category_id}
 *
 *  delete a category level
 */
export const deleteCategory = async (id) => {
	try {
		const response = await axiosInstance.delete(`/category/${id}`);
		return response;
	} catch (error) {
		throw error;
	}
};
