import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { DragDropContext } from "@hello-pangea/dnd";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import StoriesTable from "components/StoriesTable/StoriesTable";
import StoryModal from "components/StoryModal/StoryModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import { getStories, createStory, updateStory, deleteStory } from "api/stories";

const Stories = () => {
	const { t } = useTranslation();

	// table - states
	const [stories, setStories] = useState([]);
	const [story, setStory] = useState({});
	const [isDuplicate, setIsDuplicate] = useState(false);
	const [refreshTable, setRefreshTable] = useState(true);

	// modals - states
	const [showStoryModal, setShowStoryModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// table - actions

	const rowActions = [];

	rowActions.push({
		name: "edit",
		icon: "ri-pencil-line",
		command: handleEditAction,
		title: t("Edit story"),
	});

	rowActions.push({
		name: "duplicate",
		icon: "ri-file-copy-line",
		command: handleDuplicateAction,
		title: t("Duplicate story"),
	});

	rowActions.push({
		name: "lock",
		icon: "ri-delete-bin-line",
		command: handleDeleteAction,
		title: t("Delete story"),
	});

	function handleEditAction(row) {
		setStory(row);
		setShowStoryModal(true);
	}

	function handleDuplicateAction(row) {
		setStory(row);
		setIsDuplicate(true);
		setShowStoryModal(true);
	}

	function handleDeleteAction(row) {
		setStory(row);
		setShowDeleteModal(true);
	}

	// create a story

	async function handleSubmit(data) {
		const props = {
			title: data.title,
			description: data.description,
			isActive: data.is_active,
			isClassStudy: data.is_class,
			isSelfStudy: data.is_self_study,
			isShared: data.is_shared,
			patientId: data.patient_id,
			categoryId: data.category_id,
			difficultyId: data.difficulty_id,
			courseId: data.course_id,
			sourceId: data.source_id,
		};

		if (story && !isDuplicate) {
			try {
				const response = await updateStory({ storyId: story.id, ...props });

				if (response.code === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.response.data));
				} else {
					toast.success(t("Story updated!"));
					setRefreshTable(true);
				}
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setShowStoryModal(false);
			}
		} else {
			try {
				await createStory(props);
				toast.success(t("Story created!"));
				setRefreshTable(true);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setShowStoryModal(false);
			}
		}
	}

	// delete a story

	const handleDelete = async () => {
		try {
			const response = await deleteStory(story.id);

			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(
					t(
						"This story is linked to one or more classes. Please remove it from those classes before deleting.",
					),
				);
			} else {
				toast.success(t("Story deleted!"));
				setRefreshTable(true);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowDeleteModal(false);
		}
	};

	// get the stories

	useEffect(() => {
		if (!refreshTable) return;

		const fetchStories = async () => {
			try {
				const response = await getStories();
				setStories(response.data);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshTable(false);
			}
		};

		fetchStories();
	}, [refreshTable, t]);

	return (
		<>
			<div className="view">
				<div className="header">
					<h1>{t("Stories")}</h1>
					<HelpTooltip
						text={t(
							"List of all the stories. Teachers can add a new story and and use it in classes. Selected stories can also be made available for students doing home sessions. This view is only visible for teachers and admin.",
						)}
					/>
				</div>

				<div className="content">
					<DragDropContext>
						<StoriesTable
							id="all-stories"
							data={stories}
							actions={rowActions}
							newAction={() => {
								setStory(null);
								setShowStoryModal(true);
							}}
							newActionLabel={t("Create new story")}
							showFilter={true}
							isDragDisabled={true}
							onRowClick={handleEditAction}
						/>
					</DragDropContext>
				</div>
			</div>
			{showStoryModal && (
				<StoryModal
					storyData={story}
					isDuplicate={isDuplicate}
					onClose={() => {
						setStory(null);
						setIsDuplicate(false);
						setShowStoryModal(false);
					}}
					onSubmit={handleSubmit}
				/>
			)}
			{showDeleteModal && (
				<ConfirmationModal
					title={t("Delete story?")}
					message={`${t(
						"Are you sure you want to delete the story with the ID",
					)} ${story.id}? ${t("This operation cannot be undone.")}`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeleteModal(false)}
					onSubmit={handleDelete}
				/>
			)}{" "}
		</>
	);
};

Stories.displayName = "Stories";

export default Stories;
