import axiosInstance from "api/axiosInstance";

/**
 *  GET /patient/user
 *
 *  get all patients
 */
export const getPatients = async () => {
	return await axiosInstance
		.get("/patient/user")
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /patient/user
 *
 *  create a patient
 */
export const createPatient = async ({
	firstName,
	lastName,
	gender,
	birthDate,
	bodyWeight,
	bodyUnitId,
	kidneyFunction,
	patientHistory,
}) => {
	let data = {
		first_name: firstName,
		last_name: lastName,
		gender: gender,
		birth_date: birthDate,
		body_weight: bodyWeight,
		body_unit_id: bodyUnitId,
		kidney_function: kidneyFunction,
		patient_history: patientHistory,
	};

	return await axiosInstance
		.post("/patient/user", data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /patient/user/{patient_id}
 *
 *  get a patient
 */
export const getPatient = async (id) => {
	return await axiosInstance
		.get(`/patient/user/${id}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /patient/user/{patient_id}
 *
 *  update a patient
 */
export const updatePatient = async ({
	id,
	firstName,
	lastName,
	gender,
	birthDate,
	bodyWeight,
	bodyUnitId,
	kidneyFunction,
	patientHistory,
}) => {
	let data = {
		first_name: firstName,
		last_name: lastName,
		gender: gender,
		birth_date: birthDate,
		body_weight: bodyWeight,
		body_unit_id: bodyUnitId,
		kidney_function: kidneyFunction,
		patient_history: patientHistory,
	};

	return await axiosInstance
		.put(`/patient/user/${id}`, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /patient/user/{patient_id}
 *
 *  delete a patient
 */
export const deletePatient = async (id) => {
	return await axiosInstance
		.delete(`/patient/user/${id}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
