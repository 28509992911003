import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "./../../context/AuthContext";
import { useUser } from "./../../context/UserContext";
import { validateCode } from "api/auth.js";
import { getCurrentUser } from "api/users";

import Input from "./../../components/Form/Input/Input";
import Password from "./../../components/Form/Input/Password";
import Button from "./../../components/Form/Button/Button";

const validateInput = () => {
  let isValid = true;

  // validation comes here

  return isValid;
};

/**
 * STEP 2/2 - Validate code input form
 */
const ValidateCode = () => {
  const { t /*, i18n*/ } = useTranslation();
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [validCode, setValidCode] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const { setAuthData } = useAuth();
  const { userData, setUserData } = useUser();

  console.log(userData);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation
    setValidCode(validateInput());

    // authentication
    if (validCode) {
      const response = await validateCode({
        email: userData.email,
        code: code,
        password: password,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        const errorDetails = response.response.data.detail;
        if (typeof errorDetails === "string")
          setErrorMsg(t(response.response.data.detail));
        if (typeof errorDetails === "object")
          setErrorMsg(t(response.response.data.detail[0].msg));
      } else {
        setAuthData(response);

        const user = await getCurrentUser(response.access_token);
        setUserData(user);

        setCode("");
        setPassword("");
        navigate("/dashboard");
      }
    }
  };

  return (
    <>
      <form
        className="card w-80 p-5 my-2 flex flex-col gap-y-5"
        onSubmit={handleSubmit}
      >
        <h2>{t("Input verification code")}</h2>
        <p className="text-sm">
          {t(
            "Type in the verification code you received per E-mail. If the code is valid, your new account will be created.",
          )}
        </p>
        <p className="error">{errorMsg}</p>
        <Input
          id="email"
          className="mt-4"
          label={t("E-mail")}
          type="text"
          value={userData.email}
          required
          readOnly
        />
        <Input
          id="code"
          className="mt-4"
          label={t("Verification code")}
          type="number"
          value={code}
          required
          onChange={(e) => setCode(e.target.value)}
        />
        <Password
          id="password"
          className="mt-4"
          label={t("Re-enter your password")}
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button className="btn-primary my-8">{t("Verify")}</Button>
      </form>
      <Link className="font-sm" to="/login">
        {t("Return to the log in page")}
      </Link>
    </>
  );
};

export default ValidateCode;
