import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { DragDropContext } from "@hello-pangea/dnd";

import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import ClassHeader from "./ClassHeader";
import StoriesTable from "components/StoriesTable/StoriesTable";
import ClassSessionsTable from "components/ClassSessionsTable/ClassSessionsTable";
import EditClassModal from "components/EditClassModal/EditClassModal";
import StoryModal from "components/StoryModal/StoryModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import InformationModal from "components/InformationModal/InformationModal";

import {
	getClass,
	updateClass,
	deleteClass,
	getStoriesFromClass,
	addStoriesToClass,
	removeStoriesFromClass,
} from "api/classes";
import { getClassSessions } from "api/sessions";

import { getStories, createStory } from "api/stories";

import { useUser } from "context/UserContext";

import { getDate } from "utils/utils";

const SingleClass = ({ readOnly = false }) => {
	const { classId } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { canI } = useUser();

	const [singleClass, setSingleClass] = useState(null);
	const [allStories, setAllStories] = useState([]);
	const [availableStories, setAvailableStories] = useState([]);
	const [classStories, setClassStories] = useState([]);
	const [classSessions, setClassSessions] = useState([]);

	const [isLoadingAvailableStories, setIsLoadingAvailableStories] =
		useState(false);
	const [isLoadingClassStories, setIsLoadingClassStories] = useState(false);

	const [refreshClass, setRefreshClass] = useState(true);
	const [refreshStories, setRefreshStories] = useState(true);
	const [refreshClassStories, setRefreshClassStories] = useState(true);
	const [refreshSubmissionsTable, setRefreshSubmissionsTable] = useState(true);

	// modals - states
	const [showEditClassModal, setShowEditClassModal] = useState(false);
	const [showConfirmPublishModal, setShowConfirmPublishModal] = useState(false);
	const [showDeleteClassModal, setShowDeleteClassModal] = useState(false);
	const [showStoryModal, setShowStoryModal] = useState(false);
	const [showSendClassIdModal, setShowSendClassIdModal] = useState(false);

	/**
	 * class sessions table - columns & actions
	 *
	 *  these are the actions allowed depending on the role
	 */

	const columns = useMemo(
		() => [
			{
				Header: "Author",
				accessor: "creator",
				cssClass: "font-medium",
			},
			{
				Header: "Date created",
				accessor: "created_at",
				cssClass: "text-center min-cell-width",
				Cell: ({ cell: { value } }) => getDate(value),
			},
			{
				Header: "Grade",
				accessor: "grade",
				cssClass: "text-center text-xs min-cell-width",
				Cell: ({ cell: { value } }) =>
					value ? <Badge className="badge-status" value={value} /> : "-",
			},
			{
				Header: "Reviewer",
				accessor: "reviewer",
				cssClass: "min-cell-width text-center font-medium",
			},
		],
		[],
	);

	const rowActions = [];

	if (canI("update", "Patient") && singleClass?.is_published)
		rowActions.push({
			name: "edit",
			icon: "ri-file-check-line",
			command: (row) => {
				console.log(
					"Reviewing story",
					row.id,
					"from class",
					singleClass.id,
					`/classes/${singleClass.id}/review/${row.id}`,
				);
				navigate(`/classes/${singleClass.id}/review/${row.id}`);
			},
			title: t("Review case"),
		});

	const LocalActions = () => {
		return (
			<div className="local-actions -ml-1 flex items-center text-gray-500">
				<Button
					className="h-8 w-6 p-0 mr-2 btn-action font-light text-2xl text-inherit"
					title={t("Back")}
					onClick={() => navigate(-1)}
				>
					<i className="ri-arrow-left-line"></i>
				</Button>
				<Button
					className="inline-action mr-0 inline-flex gap-1 items-center"
					onClick={() => setShowEditClassModal(true)}
					title={t("Edit title")}
				>
					<i className="ri-pencil-line"></i>
					<span className="text-sm">{t("Edit title")}</span>
				</Button>
				{/*<Button
					className="inline-action mr-0 inline-flex gap-1 items-center"
					onClick={handleSaveClass}
					title={t("Save class")}
				>
					<i className="ri-save-line"></i>
					<span className="text-sm">{t("Save class")}</span>
				</Button>*/}
				<Button
					className="inline-action mr-0 inline-flex gap-1 items-center"
					onClick={() => setShowConfirmPublishModal(true)}
					title={
						singleClass.is_published ? t("Unpublish class") : t("Publish class")
					}
				>
					<i
						className={
							singleClass.is_published ? "ri-eye-off-line" : "ri-eye-line"
						}
					></i>
					<span className="text-sm">
						{singleClass.is_published
							? t("Unpublish class")
							: t("Publish class")}
					</span>
				</Button>
				<Button
					className="inline-action mr-0 inline-flex gap-1 items-center"
					onClick={() => setShowDeleteClassModal(true)}
				>
					<i className="ri-delete-bin-line"></i>
					<span className="text-sm">{t("Delete class")}</span>
				</Button>
				{singleClass?.is_published ? (
					<>
						<Button
							className="inline-action mr-0 inline-flex gap-1 items-center"
							onClick={handleReviewAllSessions}
						>
							<i className="ri-file-check-line"></i>
							<span className="text-sm">{t("Review all sessions")}</span>
						</Button>

						<Button
							className="inline-action mr-0 inline-flex gap-1 items-center"
							onClick={handleSendAllFeedback}
						>
							<i className="ri-mail-send-line"></i>
							<span className="text-sm">{t("Send all feedback")}</span>
						</Button>
					</>
				) : (
					""
				)}
				<HelpTooltip
					text={t(
						"Use the single class view to design or edit a class.\nAfter publishing you have an overview of the submitted sessions.",
					)}
				/>
			</div>
		);
	};

	/**
	 * ======== class handlers ========
	 *
	 * - update class
	 * - save class
	 * - publish class
	 * - delete class
	 * - review all sessions
	 * - send all feedback
	 */

	const handleUpdateClass = async ({ description, isActive, isPublished }) => {
		try {
			const response = await updateClass({
				classId,
				description,
				isActive,
				isPublished,
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			}

			setSingleClass({
				...singleClass,
				description,
				is_active: isActive,
				is_published: isPublished,
			});

			toast.success(t("Class updated!"));
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowEditClassModal(false);
		}
	};

	/*
	const handleSaveClass = async () => {
		try {
			const response = await addStoriesToClass({
				classId,
				storyIds: classStories.map((story) => story.id),
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			}

			setRefreshClassStories(true);

			toast.success(t("Class saved!"));
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};
	*/

	const handlePublishClass = async () => {
		const isPublished = !singleClass.is_published;

		try {
			const response = await updateClass({
				classId,
				description: singleClass.description,
				isActive: singleClass.is_active,
				isPublished,
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			}

			setSingleClass({
				...singleClass,
				description: singleClass.description,
				is_active: singleClass.is_active,
				is_published: isPublished,
			});

			if (isPublished) {
				setShowSendClassIdModal(true);
				toast.success(t("Class published!"));
			} else {
				toast.success(t("Class unpublished!"));
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowConfirmPublishModal(false);
		}
	};

	const handleDeleteClassConfirmed = async () => {
		try {
			const response = await deleteClass(classId);
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			}

			navigate("/classes");

			toast.success(t("Class deactivated!"));
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowDeleteClassModal(false);
		}
	};

	const handleReviewAllSessions = () => {
		alert("Review all sessions");
		console.log("Review all sessions");
	};

	const handleSendAllFeedback = () => {
		alert("Send all feedback");
		console.log("Send all feedback");
	};

	/**
	 * ======== stories handlers ========
	 *
	 * - handle submit story
	 * - handle add story by id
	 * - handle remove story by id
	 * - handle drag end
	 */

	async function handleSubmitStory(data) {
		try {
			const response = await createStory({ ...data });

			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				// add story to the list of selected class stories
				// it will automatically be removed from the available stories
				setClassStories([...classStories, response]);
				toast.success(t("Story created!"));
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowStoryModal(false);
		}
	}

	const addStoryToClass = async (id, order) => {
		console.log("adding story with id", id);

		try {
			const response = await addStoriesToClass({
				classId,
				storyIds: [id],
				order,
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const removeStoryFromClass = async (id) => {
		console.log("removing story with id", id);

		try {
			const response = await removeStoriesFromClass({
				classId,
				storyIds: [id],
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const updateStoriesOrder = async (stories) => {
		console.log("updating stories order");

		try {
			const response = await addStoriesToClass({
				classId,
				storyIds: stories.map((story) => story.id),
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const addStoryToClassList = (source, destination) => {
		const sourceItems = Array.from(availableStories);
		const [movedItem] = sourceItems.splice(source.index, 1);
		setAvailableStories(sourceItems);

		const destinationItems = Array.from(classStories);
		destinationItems.splice(destination.index, 0, movedItem);
		setClassStories(destinationItems);
	};

	const removeStoryFromClassList = (source, destination) => {
		const sourceItems = Array.from(classStories);
		const [movedItem] = sourceItems.splice(source.index, 1);
		setClassStories(sourceItems);

		const destinationItems = Array.from(availableStories);
		destinationItems.splice(destination.index, 0, movedItem);
		setAvailableStories(destinationItems);
	};

	const reorderList = (list, startIndex, endIndex) => {
		const items = Array.from(list);

		const [reorderedItem] = items.splice(startIndex, 1);
		items.splice(endIndex, 0, reorderedItem);

		return items;
	};

	const handleDragEnd = (event) => {
		const { source, destination, draggableId } = event;

		// dropped outside any droppable area does nothing
		if (!destination) return;

		/**
		 * reorder in the list or move between lists
		 */
		if (source.droppableId === destination.droppableId) {
			if (source.index !== destination.index) {
				if (source.droppableId === "class-stories") {
					const items = reorderList(
						classStories,
						source.index,
						destination.index,
					);
					setClassStories(items);
					// update the database with the new order
					updateStoriesOrder(items);
				}
				if (source.droppableId === "available-stories") {
					const items = reorderList(
						availableStories,
						source.index,
						destination.index,
					);
					setAvailableStories(items);
				}
			}
		} else {
			// move item from one list to another only altering the classStories and availableStories states
			if (source.droppableId === "class-stories") {
				removeStoryFromClassList(source, destination);
				// update the database as well
				removeStoryFromClass(draggableId);
			} else {
				addStoryToClassList(source, destination);
				// update the database as well
				addStoryToClass(draggableId, destination);
			}
		}
	};

	// get the class data

	useEffect(() => {
		if (!refreshClass) return;

		const fetchClass = async () => {
			try {
				const response = await getClass(classId);
				setSingleClass(response);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshClass(false);
			}
		};

		fetchClass();
	}, [classId, refreshClass]);

	// get all active stories

	useEffect(() => {
		if (!refreshStories) return;

		const fetchStories = async () => {
			try {
				setIsLoadingAvailableStories(true);
				const response = await getStories();
				response.data = response.data.filter((story) => story.is_active);
				setAllStories(response.data);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setIsLoadingAvailableStories(false);
				setRefreshStories(false);
			}
		};

		fetchStories();
	}, [refreshStories]);

	// get the class stories

	useEffect(() => {
		if (!refreshClassStories) return;

		const fetchClassStories = async () => {
			try {
				setIsLoadingClassStories(true);
				const response = await getStoriesFromClass(classId);
				setClassStories(response);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setIsLoadingClassStories(false);
				setRefreshClassStories(false);
			}
		};

		fetchClassStories();
	}, [classId, refreshClassStories]);

	// compute the available stories (right side panel) with a derived state

	useEffect(() => {
		const selectedIds = classStories.map((story) => story?.id);
		setAvailableStories(
			allStories.filter((story) => !selectedIds.includes(story.id)),
		);
	}, [allStories, classStories]);

	// get the submitted sessions

	useEffect(() => {
		if (!refreshSubmissionsTable) return;

		const fetchSessions = async () => {
			try {
				const data = await getClassSessions(classId);
				setClassSessions(data);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshSubmissionsTable(false);
			}
		};

		fetchSessions();
	}, [classId, refreshSubmissionsTable]);

	if (singleClass === null) return null;

	return (
		<>
			<div className="single-class h-full flex gap-4 items-stretch">
				<div className="view w-full">
					<LocalActions />
					<ClassHeader singleClass={singleClass} />
					<DragDropContext onDragEnd={handleDragEnd}>
						<div className="content ml-8 mt-2 overflow-hidden">
							<div className="h-full mb-2 flex gap-24">
								<div className="w-1/2 flex flex-col gap-4">
									<div className="flex gap-2">
										<h2 className="m-0">{t("Stories in this class")}</h2>
										<HelpTooltip
											text={t(
												"List of stories selected for this class. You can change the order by dragging and dropping the stories.",
											)}
										/>
									</div>
									<StoriesTable
										id="class-stories"
										data={classStories}
										actions={rowActions}
										newAction={() =>
											!singleClass.is_published && setShowStoryModal(true)
										}
										showSearch={false}
										noDataMessage={
											"No stories selected yet.\nAdd stories from the existing ones (right column) or define new ones."
										}
										isLoading={isLoadingClassStories}
										isDragDisabled={singleClass.is_published}
										onDragEnd={handleDragEnd}
									/>
								</div>

								<aside className="w-1/2 h-full flex flex-col gap-4">
									{!singleClass.is_published ? (
										<>
											<h2 className="m-0">{t("Available stories")}</h2>
											<StoriesTable
												id="available-stories"
												data={availableStories}
												noDataMessage={t("No (further) stories available.")}
												actions={[]}
												showSearch={true}
												showFilter={true}
												isLoading={isLoadingAvailableStories}
												onDragEnd={handleDragEnd}
											/>
										</>
									) : (
										<>
											<div className="flex gap-2">
												<h2 className="m-0">
													{t("List of sessions in this class")}
												</h2>
												<HelpTooltip
													text={t(
														"List of all the sessions submitted for this class. You can review them and give a grade.",
													)}
												/>
											</div>
											<ClassSessionsTable
												data={classSessions}
												columns={columns}
												readOnly={readOnly}
												showSearch={false}
												onRowClick={(row) => {
													navigate(`/session/${row.original.id}/review`);
												}}
											/>
										</>
									)}
								</aside>
							</div>
						</div>
					</DragDropContext>
				</div>
			</div>

			{showEditClassModal && (
				<EditClassModal
					classId={classId}
					description={singleClass.description}
					isActive={singleClass.is_active}
					isPublished={singleClass.is_published}
					onClose={() => setShowEditClassModal(false)}
					onSubmit={handleUpdateClass}
				/>
			)}

			{showConfirmPublishModal && (
				<ConfirmationModal
					title={
						singleClass.is_published
							? t("Unpublish class?")
							: t("Publish class?")
					}
					message={
						singleClass.is_published
							? t("Are you sure you want to unpublish this class?")
							: t("Are you sure you want to publish this class?")
					}
					yesMessage={singleClass.is_published ? t("Unpublish") : t("Publish")}
					onClose={() => setShowConfirmPublishModal(false)}
					onSubmit={handlePublishClass}
				/>
			)}

			{showSendClassIdModal && (
				<InformationModal
					title={t("Class created!")}
					onClose={() => setShowSendClassIdModal(false)}
					onSubmit={() => setShowSendClassIdModal(false)}
				>
					<div className="flex flex-col gap-2">
						<p className="self-center">{t("Class ID")}</p>
						<p className="self-center text-2xl font-bold">{classId}</p>
						<p>
							{t(
								`The class was successfully created! You have to send the students the class ID manually.`,
							)}
						</p>
					</div>
				</InformationModal>
			)}

			{showDeleteClassModal && (
				<ConfirmationModal
					title={t("Delete class?")}
					message={`${t(
						"Are you sure you want to delete the class with the ID",
					)} ${classId}? ${t("This operation cannot be undone.")}`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeleteClassModal(false)}
					onSubmit={handleDeleteClassConfirmed}
				/>
			)}

			{showStoryModal && (
				<StoryModal
					onClose={() => setShowStoryModal(false)}
					onSubmit={handleSubmitStory}
				/>
			)}
		</>
	);
};

SingleClass.displayName = "SingleClass";

export default SingleClass;
