import axiosInstance from "api/axiosInstance";

/**
 *  GET /treatment/user/{session_id}
 *
 *  get all case (treatment plans) from a session
 */
export const getCases = async (id) => {
	return await axiosInstance
		.get(`/treatment/user/${id}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /treatment/{session_id}
 *
 *  create a case (treatment plan)
 */
export const createCase = async ({ sessionId, patientId }) => {
	let data = {
		patient_id: patientId,
	};

	return await axiosInstance
		.post(`/treatment/${sessionId}`, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /treatment/{treatment_plan_id}
 *
 *  get a case (treatment plan) by id
 */
export const getCase = async (treatmentPlanId) => {
	return await axiosInstance
		.get(`/treatment/${treatmentPlanId}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /treatment/{treatment_plan_id}
 *
 *  update a case (treatment plan)
 */
export const updateCase = async ({ id, patientId }) => {
	let data = {
		patient_id: patientId,
	};

	return await axiosInstance
		.put(`/treatment/${id}`, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /treatment/{treatment_plan_id}
 *
 *  delete a case (treatment plan)
 */
export const deleteCase = async (caseId) => {
	return await axiosInstance
		.delete(`/treatment/${caseId}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
