import axiosInstance from "api/axiosInstance";

/**
 *  PUT /review/session/{session_id}
 *
 *  review a session
 */
export const reviewSession = async ({ id, gradeId, notes }) => {
	let data = {
		grade_id: gradeId,
		reviewer_notes: notes,
	};

	return await axiosInstance
		.put(`/review/session/${id}`, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /review/session/{session_id}/submit
 *
 *  submit the review for a session
 */
export const submitReviewedSession = async (id) => {
	let data = { body: "empty_body" };

	return await axiosInstance
		.put(`/review/submit/${id}`, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /review/treatment_plan/{treatment_plan_id}
 *
 *  review a treatment plan
 */
export const reviewTreatmentPlan = async ({ id, gradeId, notes }) => {
	let data = {
		grade_id: gradeId,
		reviewer_notes: notes,
	};

	return await axiosInstance
		.put(`/review/treatment_plan/${id}`, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /review/prescription/{prescription_id}
 *
 *  review a prescription
 */
export const reviewPrescription = async ({ id, gradeId, notes }) => {
	let data = {
		grade_id: gradeId,
		reviewer_notes: notes,
	};

	return await axiosInstance
		.put(`/review/prescription/${id}`, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
