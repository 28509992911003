import axiosInstance from "api/axiosInstance";

/**
 *  GET /treatment/user/{session_id}
 *
 *  get all treatment plans from a session
 */
export const getTreatmentPlans = async (sessionId) => {
	return await axiosInstance
		.get(`/treatment/user/${sessionId}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /treatment/{session_id}
 *
 *  create a treatment plan
 */
export const createTreatmentPlan = async ({
	sessionId,
	storyId,
	patientId,
}) => {
	let data = {
		story_id: storyId,
		patient_id: patientId,
	};

	return await axiosInstance
		.post(`/treatment/${sessionId}`, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /treatment/{treatment_plan_id}
 *
 *  get a treatment plan by id
 */
export const getTreatmentPlan = async (id) => {
	return await axiosInstance
		.get(`/treatment/${id}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /treatment/{treatment_plan_id}
 *
 *  update a treatment plan
 */
export const updateTreatmentPlan = async ({ id, storyId, patientId }) => {
	let data = {
		story_id: storyId,
		patient_id: patientId,
	};

	return await axiosInstance
		.put(`/treatment/${id}`, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /treatment/{treatment_plan_id}
 *
 *  delete a treatment plan
 */
export const deleteTreatmentPlan = async (id) => {
	return await axiosInstance
		.delete(`/treatment/${id}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
