import axiosInstance from "api/axiosInstance";

/**
 *  GET /session/user
 *
 *  get all sessions
 */
export const getSessions = async () => {
	return await axiosInstance
		.get("/session/user")
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /session/{session_id}
 *
 *  get a session
 */
export const getSession = async (sessionId) => {
	return await axiosInstance
		.get(`/session/${sessionId}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /session/{session_id}
 *
 *  update a session
 */
export const updateSession = async ({
	sessionId,
	description,
	classId,
	statusId,
}) => {
	let data = {
		description,
		class_id: classId,
		status_id: statusId,
	};

	return await axiosInstance
		.put(`/session/${sessionId}`, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /session/{session_id}
 *
 *  delete a session
 */
export const deleteSession = async (sessionId) => {
	return await axiosInstance
		.delete(`/session/${sessionId}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /session
 *
 *  create a session
 */
export const createSession = async ({ description = "", class_id = null }) => {
	let data = {
		description,
		class_id,
	};

	return await axiosInstance
		.post("/session", data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /session/class/{class_id}
 *
 *  get a all sessions of a class
 */
export const getClassSessions = async (classId) => {
	return await axiosInstance
		.get(`/session/class/${classId}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
