import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Button from "components/Form/Button/Button";

import { getStory } from "api/stories";

const StoryWidget = ({
  storyId,
  className = "",
  hintText = "",
  onSubmit = null,
}) => {
  const [story, setStory] = useState(null);
  const { t } = useTranslation();
  const classes = [
    "story-widget min-h-[8rem] p-4 flex flex-col justify-center relative bg-slate-100 border rounded font-sm",
  ];

  if (className) classes.push(className);

  useEffect(() => {
    if (!storyId) return;

    const loadStory = async () => {
      try {
        const response = await getStory(storyId);
        setStory(response);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    loadStory();
  }, [storyId]);

  return (
    <div className={classes.join(" ")}>
      {_.isEmpty(story) && (
        <div className="no-story flex flex-col center">
          {hintText && <p className="mb-2 text-sm">{hintText}</p>}
          <Button className="btn-primary" onClick={onSubmit}>
            {t("Select story")}
          </Button>
        </div>
      )}
      {storyId && (
        <p className="max-w-3xl">{story?.description || "Loading.."}</p>
      )}
    </div>
  );
};

export default StoryWidget;
