import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // add headers or perform other actions before making a request
    const authData = JSON.parse(localStorage.getItem("authData"));

    if (authData && authData.access_token) {
      config.headers.Authorization = `Bearer ${authData.access_token}`;
    }

    return config;
  },
  (error) => {
    // const { showError } = useError();
    // handle request errors here (e.g., network issues)
    return Promise.reject(error);
  },
);

// Axios response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response?.status === 401 &&
      error.response?.data?.detail === "Invalid credentials"
    ) {
      localStorage.removeItem("authData");
      localStorage.removeItem("userData");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
