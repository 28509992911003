import axiosInstance from "api/axiosInstance";

/**
 *  GET /story
 *
 *  get all stories
 */
export const getStories = async () => {
	return await axiosInstance
		.get("/story?per_page=50")
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 * GET /story/{story_id}
 *
 * get a story by id
 */
export const getStory = async (storyId) => {
	return await axiosInstance
		.get(`/story/${storyId}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /story
 *
 *  create a story
 */
export const createStory = async ({
	title,
	description,
	isActive,
	isClassStudy,
	isSelfStudy,
	isShared,
	patientId,
	categoryId,
	difficultyId,
	courseId,
	sourceId,
}) => {
	let data = {
		title: title,
		description: description,
		is_active: isActive,
		is_class: isClassStudy,
		is_self: isSelfStudy,
		is_shared: isShared,
		patient_id: patientId,
		category_id: categoryId,
		difficulty_id: difficultyId,
		course_id: courseId,
		source_id: sourceId,
	};

	return await axiosInstance
		.post("/story", data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /story/{story_id}
 *
 *  update a story
 */
export const updateStory = async ({
	storyId,
	title,
	description,
	isActive,
	isClassStudy,
	isSelfStudy,
	isShared,
	patientId,
	categoryId,
	difficultyId,
	courseId,
	sourceId,
}) => {
	let data = {
		title: title,
		description: description,
		is_active: isActive,
		is_class: isClassStudy,
		is_self_study: isSelfStudy,
		is_shared: isShared,
		patient_id: patientId,
		category_id: categoryId,
		difficulty_id: difficultyId,
		course_id: courseId,
		source_id: sourceId,
	};

	return await axiosInstance
		.put(`/story/${storyId}`, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /story/{story_id}
 *
 *  delete a story
 */
export const deleteStory = async (storyId) => {
	return await axiosInstance
		.delete(`/story/${storyId}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
