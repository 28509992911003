import axiosInstance from "api/axiosInstance";

/**
 *  GET /class
 *
 *  get all classes
 */
export const getClasses = async () => {
	return await axiosInstance
		.get("/class")
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /class
 *
 *  create a class
 */
export const createClass = async ({ description, isActive, isPublished }) => {
	let data = {
		description: description,
		is_active: isActive,
		is_published: isPublished,
	};

	return await axiosInstance
		.post("/class", data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /class/{class_id}
 *
 *  get a class
 */
export const getClass = async (classId) => {
	return await axiosInstance
		.get(`/class/${classId}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /class/{class_id}
 *
 *  update a class
 */
export const updateClass = async ({
	classId,
	description,
	isActive,
	isPublished,
}) => {
	const data = {
		description: description,
		is_active: isActive,
		is_published: isPublished,
	};

	return await axiosInstance
		.put(`/class/${classId}`, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /class/{class_id}
 *
 *  delete a class
 */
export const deleteClass = async (classId) => {
	return await axiosInstance
		.delete(`/class/${classId}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /class/{class_id}/stories
 *
 *  get all stories from a class
 */
export const getStoriesFromClass = async (classId) => {
	return await axiosInstance
		.get(`/class/${classId}/stories`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 * POST /class/{class_id}/stories
 *
 *  add stories to a class
 */
export const addStoriesToClass = async ({ classId, storyIds }) => {
	return await axiosInstance
		.post(`/class/${classId}/stories`, { story_ids: storyIds })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 * DELETE /class/{class_id}/stories
 *
 *  remove stories from a class
 */
export const removeStoriesFromClass = async ({ classId, storyIds }) => {
	console.log("removeStoriesFromClass", classId, storyIds);
	return await axiosInstance
		// use the "data" key for the payload since usually the second param for DELETE is "config"
		.delete(`/class/${classId}/stories`, { data: { story_ids: storyIds } })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
