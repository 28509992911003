import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { getPatients, getPatient } from "api/patients";
import { getDifficulties } from "api/difficulties";
import { getCategories } from "api/categories";
import { getCourses } from "api/courses";

import Modal from "components/Modal/Modal";
import PatientsTable from "components/PatientsTable/PatientsTable";
import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";
import Input from "components/Form/Input/Input";
import Textarea from "components/Form/Textarea/Textarea";
import Toggle from "components/Form/Toggle/Toggle";
import Select from "components/Form/Select/Select";
import { getDate } from "utils/utils";

import "./StoryModal.scss";

const StoryModal = ({ storyData, isDuplicate, onSubmit, ...props }) => {
	const { t } = useTranslation();

	// table - states
	const [patients, setPatients] = useState([]);
	const [patient, setPatient] = useState(null);
	const [difficultyOptions, setDifficultyOptions] = useState([]);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [courseOptions, setCourseOptions] = useState([]);
	const historyBadges = [];

	const [step, setStep] = useState(storyData === null ? 1 : 2);

	const Steps = { SELECT_PATIENT: 1, EDIT_STORY: 2 };

	if (patient?.patient_history)
		patient["patient_history"].forEach((item, idx) => {
			if (item.description)
				historyBadges.push(
					<Badge key={idx} className="bg-slate-400" value={item.description} />,
				);
		});
	if (historyBadges.length === 0) historyBadges.push("-");

	const [story, setStory] = useState({
		title: storyData?.title || "",
		description: storyData?.description || "",
		patient_id: storyData?.patient_id || null,
		difficulty_id: storyData?.difficulty_id || null,
		category_id: storyData?.category_id || null,
		course_id: storyData?.course_id || null,
		is_active: storyData?.is_active ?? true, // Only default to true if undefined or null
		is_class: storyData?.is_class ?? true,
		is_self_study: storyData?.is_self_study ?? false,
		is_shared: storyData?.is_shared ?? false,
	});

	const modalTitle = !storyData
		? t("Create new story")
		: isDuplicate
		? t("Duplicate story")
		: t("Edit story");

	const modalMessage = !storyData
		? t(
				"You are going to add a story. After submitting, changes are possible until the story has been used in a case.",
		  )
		: isDuplicate
		? t(
				"You are going to duplicate a story. After making the wished changes you can submit the story.",
		  )
		: t(
				"You are going to edit a story. After submitting, changes are possible until the story has been used in a case.",
		  );

	const validInputs = () => {
		let result = true;

		return result;
	};

	const handleClose = () => {
		setStory(null);
		props.onClose();
	};

	const handleSubmit = () => {
		if (validInputs()) {
			onSubmit && onSubmit(story);
			setStory(null);
			props.onClose();
		}
	};

	const applyFilters = (data) => {
		// only return active elements
		return data.filter((object) => object.is_active);
	};

	// set the patient

	const handleRowClick = async (row) => {
		const descriptionStart = `${row.original.first_name} ${
			row.original.last_name
		}, ${t("born on")} ${getDate(row.original.birth_date)}, ...`;

		setStory({
			...story,
			patient_id: row.original.id,
			description: `${descriptionStart}\n\n${story.description}`,
		});

		// get complete patient data including patient_history

		try {
			const response = await getPatient(row.original.id);

			if (response === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.response.data));
			} else {
				setPatient(response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	/* set the title of a duplicated story */

	useEffect(() => {
		if (isDuplicate) {
			setStory({
				...storyData,
				title: [t("Copy of"), storyData.title].join(" "),
			});
		}
	}, [isDuplicate, storyData, t]);

	/* get all patients */

	useEffect(() => {
		const fetchPatients = async () => {
			try {
				const patientsData = await getPatients();
				setPatients(applyFilters(patientsData));
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchPatients();
	}, [t]);

	/* get the current patient if any */

	useEffect(() => {
		if (!story.patient_id) return;

		const fetchPatient = async () => {
			try {
				const response = await getPatient(story.patient_id);
				setPatient(response);
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchPatient();
	}, [story.patient_id, t]);

	/* get the difficulties */

	useEffect(() => {
		const fetchDifficulties = async () => {
			try {
				const response = await getDifficulties();
				setDifficultyOptions(
					response.data.data.map((item) => ({
						value: item.id,
						label: item.name,
					})),
				);
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchDifficulties();
	}, []);

	/* get the categories */

	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const response = await getCategories();
				setCategoryOptions(
					response.data.data.map((item) => ({
						value: item.id,
						label: item.name,
					})),
				);
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchCategories();
	}, []);

	/* get the courses */

	useEffect(() => {
		const fetchCourses = async () => {
			try {
				const response = await getCourses();
				setCourseOptions(
					response.data.data.map((item) => ({
						value: item.id,
						label: item.name,
					})),
				);
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchCourses();
	}, []);

	return (
		<Modal
			title={modalTitle}
			isOpen={props.isOpen}
			onClose={handleClose}
			className="story-modal w-3/5 h-4/5 min-w-[1200px]"
		>
			<div className="h-full max-h-full flex flex-col gap-8">
				<p className="message__note">
					<i className="ri-book-open-line"></i>
					{modalMessage}
				</p>
				<form
					className="flex-1 flex gap-6 overflow-hidden"
					onSubmit={handleSubmit}
				>
					{/* Step 1 - select the patient */}

					{step === Steps.SELECT_PATIENT && (
						<div className="select-patient w-full max-h-full">
							<div className="w-full flex flex-col gap-4 max-h-full">
								<h3>{t("Patient list")}</h3>
								<PatientsTable
									data={patients}
									searchLabel={t("Search by first name and/or last name")}
									initialState={{
										hiddenColumns: ["accessor", "is_active"],
									}}
									onRowClick={handleRowClick}
								/>
							</div>
						</div>
					)}

					{/* Step 2 - edit the story */}

					{step === Steps.EDIT_STORY && (
						<div className="edit-case pt-4 w-full h-full flex">
							<div className="w-full p-1 flex flex-col gap-8">
								<Input
									id="title"
									label={t("Title")}
									value={story.title}
									autoFocus={true}
									required
									onChange={(e) =>
										setStory({ ...story, title: e.target.value })
									}
								/>
								<Textarea
									id="description"
									label={t("Description")}
									value={story.description}
									required
									onChange={(e) =>
										setStory({ ...story, description: e.target.value })
									}
								/>
								<div className="flex flex-col gap-6">
									<Select
										id="difficulty_id"
										className="w-1/2"
										label={t("Difficulty")}
										value={difficultyOptions.find(
											(item) => item.value === story.difficulty_id,
										)}
										options={difficultyOptions}
										onChange={(e) =>
											setStory({ ...story, difficulty_id: e.value })
										}
									/>
									<Select
										id="category_id"
										className="w-1/2"
										label={t("Category")}
										value={categoryOptions.find(
											(item) => item.value === story.category_id,
										)}
										options={categoryOptions}
										onChange={(e) =>
											setStory({ ...story, category_id: e.value })
										}
									/>
									<Select
										id="course_id"
										className="w-1/2"
										label={t("Course")}
										value={courseOptions.find(
											(item) => item.value === story.course_id,
										)}
										options={courseOptions}
										onChange={(e) => setStory({ ...story, course_id: e.value })}
									/>
									<div className="flex gap-6 m-3">
										<strong>{t("Scope")}</strong>
										<Toggle
											id="class"
											label={t("Class study")}
											checked={story.is_class}
											onChange={(value) =>
												setStory({ ...story, is_class: value })
											}
										/>
										<Toggle
											id="self-study"
											label={t("Self study")}
											checked={story.is_self_study}
											onChange={(value) =>
												setStory({ ...story, is_self_study: value })
											}
										/>
										<Toggle
											id="shared"
											label={t("Share with other Edups clients")}
											checked={story.is_shared}
											onChange={(value) =>
												setStory({ ...story, is_shared: value })
											}
										/>
									</div>
									<Toggle
										id="active"
										className="mx-3"
										label={t("Active")}
										checked={story.is_active}
										onChange={(value) =>
											setStory({ ...story, is_active: value })
										}
									/>
								</div>
							</div>
						</div>
					)}

					{/* aside panel with the selected patient */}

					<div className="aside p-1 pt-5 flex flex-col">
						<div className="flex flex-col gap-4 p-4 bg-slate-100 border rounded">
							<h3 className="mb-4">{t("Patient (optional)")}</h3>
							<div className="grid grid-cols-2 text-sm gap-y-2">
								<strong>{t("Patient Id")}</strong>
								<span>{patient?.patient?.id}</span>

								<strong>{t("First name")}</strong>
								<span>{patient?.patient?.first_name}</span>

								<strong>{t("Last name")}</strong>
								<span>{patient?.patient?.last_name}</span>

								<strong>{t("Date of birth")}</strong>
								<span>
									{patient?.patient?.birth_date &&
										getDate(patient.patient.birth_date)}
								</span>

								<strong>{t("Age")}</strong>
								<span>{patient?.patient?.age}</span>

								<strong>{t("Sex")}</strong>
								<span>{patient?.patient?.gender}</span>

								<strong>{t("Body weight")}</strong>
								<span>
									{`${patient?.patient?.body_weight || "-"} ${t(
										patient?.patient?.body_unit,
									)}`}
								</span>

								<strong>{t("eGFR")}</strong>
								<span>
									{patient?.patient?.kidney_function || "-"}{" "}
									{patient?.patient?.kidney_function && t("ml/min/1.73m²")}
								</span>

								<strong className="col-span-2">{t("Medical history")}</strong>
								<p className="col-span-2">{historyBadges}</p>
							</div>
						</div>
						<div className="h-full flex flex-col justify-center">
							{step === Steps.SELECT_PATIENT && (
								<Button
									className={`btn-primary mt-auto self-end ${
										!patient && "disabled"
									}`}
									onClick={() => setStep(Steps.EDIT_STORY)}
								>
									{t("Next")}
								</Button>
							)}
							{step === Steps.EDIT_STORY && (
								<>
									<Button
										className="mt-4 !mr-0 self-end"
										onClick={() => setStep(Steps.SELECT_PATIENT)}
									>
										{t("Choose a patient")}
									</Button>
									<Button
										className="btn-primary mt-auto self-end"
										onClick={handleSubmit}
									>
										{t("Save")}
									</Button>
								</>
							)}
						</div>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default StoryModal;
