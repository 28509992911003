import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Sidebar.scss";

const SideBar = ({ navLinks }) => {
  const { t } = useTranslation();
  const [sidebarIsOpen, setSidebarIsOpen] = useState(
    // !(window.innerWidth < 1400),
    false,
  );

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 1400 && sidebarIsOpen) setSidebarIsOpen(false);
      if (window.innerWidth >= 1400 && !sidebarIsOpen) setSidebarIsOpen(true);
    };

    window.addEventListener("resize", handleWindowResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [sidebarIsOpen]);

  return (
    <div
      className={`sidebar ${
        sidebarIsOpen ? "sidebar--open" : "sidebar--closed"
      }`}
    >
      <nav className="sidebar__content nav py-4">
        <ul className="nav__list flex flex-col">
          {navLinks.map((item, index) => {
            if (item.caption === "_separator_")
              return <li className={`nav__separator`} key={index}></li>;

            const classes = ["nav__item"];
            if (item.className) classes.push(item.className);

            return (
              <li className={`nav__item ${classes.join(" ")}`} key={index}>
                <NavLink
                  to={item.path}
                  className="nav__link"
                  title={item.title ? item.title : item.caption}
                >
                  <i className={item.icon}></i>
                  <span className={`${!sidebarIsOpen && "hidden"}`}>
                    {item.caption}
                  </span>
                </NavLink>
              </li>
            );
          })}
          <li className="nav__item sidebar__control">
            <button
              className="nav__button"
              onClick={(e) => {
                e.preventDefault();
                setSidebarIsOpen(!sidebarIsOpen);
              }}
            >
              <i
                className={`ri-arrow-left-s-line ${
                  !sidebarIsOpen && "rotate-180"
                }`}
              ></i>
              <span className={`caption ${!sidebarIsOpen && "hidden"}`}>
                {t("Collapse")}
              </span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SideBar;
