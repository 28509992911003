import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useUser } from "context/UserContext";
import { createClass, getClasses, updateClass, deleteClass } from "api/classes";
import { getDate } from "utils/utils";

import Badge from "components/Badge/Badge";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import FilterTabs from "components/Tabs/FilterTabs";
import Table, { Actions } from "components/Table/Table";
import NewClassModal from "components/NewClassModal/NewClassModal";
import EditClassModal from "components/EditClassModal/EditClassModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

const Classes = () => {
	const [singleClass, setSingleClass] = useState({});

	// modals - states
	const [showNewClassModal, setShowNewClassModal] = useState(false);
	const [showEditClassModal, setShowEditClassModal] = useState(false);
	const [showConfirmPublishModal, setShowConfirmPublishModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// hooks
	const navigate = useNavigate();
	const { userData } = useUser();
	const { t } = useTranslation();

	// table - states
	const [tableData, setTableData] = useState([]);
	const [refreshTable, setRefreshTable] = useState(false);
	const [classId, setClassId] = useState();
	const [description, setDescription] = useState();
	const [isActive, setIsActive] = useState();
	const [isPublished, setIsPublished] = useState();

	// table - filters
	const filters = [
		{
			key: "author",
			label: t("My classes"),
			value: `${userData.first_name} ${userData.last_name}`,
			isActive: true, // flag to signify compound condition
		},
		{ key: "is_active", label: t("All classes"), value: 1 },
		{ key: "is_active", label: t("Archived"), value: 0 },
	];
	const [filter, setFilter] = useState(filters[0]);

	const columns = useMemo(
		() =>
			[
				{
					Header: t("ID"),
					accessor: "id",
					cssClass: "min-cell-width text-center",
					Cell: ({ cell: { value } }) => (
						<Badge
							className="badge-outline"
							value={value}
							title={t("You have to send the students this class ID")}
						/>
					),
				},
				{
					Header: t("Class name"),
					accessor: "description",
					cssClass: "font-medium w-2/6 truncate",
				},
				{
					Header: t("Author"),
					accessor: "creator",
					cssClass: "w-1/6 whitespace-nowrap",
					//disableGlobalFilter: true,
				},
				{
					Header: t("Status"),
					accessor: "is_published",
					cssClass: "w-1/6 text-center text-xs",
					Cell: ({ cell: { value } }) => (
						<Badge value={value ? "published" : "not published"} />
					),
				},
				{
					Header: t("Submissions"),
					accessor: "total_sessions",
					cssClass: "text-center min-cell-width",
					Cell: ({ cell: { value } }) => (value === 0 ? "-" : value),
				},
				{
					Header: t("Date created"),
					accessor: "created_at",
					cssClass: "text-center min-cell-width",
					Cell: ({ cell: { value } }) => getDate(value),
				},
				{
					Header: t("Actions"),
					accessor: "accessor",
					disableSortBy: true,
					cssClass: "text-center min-cell-width pr-5",
					Cell: ({ row: { original } }) => {
						const rowActions = [
							{
								name: "toggle-publish",
								icon: original.is_published ? "ri-eye-off-line" : "ri-eye-line",
								command: () => {
									setSingleClass(original);
									setShowConfirmPublishModal(true);
								},
								title: t(
									original.is_published ? "Unpublish class" : "Publish class",
								),
							},
							{
								name: "delete",
								icon: "ri-delete-bin-line",
								command: () => handleDelete(original),
								title: t("Delete class"),
							},
						];
						return <Actions row={original} actions={rowActions} />;
					},
				},
			].filter((item) => item !== false),
		[t],
	);

	const createNewClass = async (description) => {
		const response = await createClass({
			description: description,
			isPublished: 0,
			isActive: 1,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
		} else {
			setClassId(response.class_id);
			setRefreshTable(true);
			navigate(`/classes/${response.class_id}`);
		}
	};

	// table - action handlers

	function handleView(id, description, is_active, is_published) {
		navigate(`/classes/${id}`);
	}

	async function handlePublishClass() {
		const isPublished = !singleClass.is_published;

		try {
			const response = await updateClass({
				classId: singleClass.id,
				description: singleClass.description,
				isActive: singleClass.is_active,
				isPublished,
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				setRefreshTable(true);

				if (isPublished) {
					toast.success(t("Class published!"));
				} else {
					toast.success(t("Class unpublished!"));
				}
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowConfirmPublishModal(false);
		}
	}

	function handleDelete(row) {
		setClassId(row.id);
		setDescription(row.description);
		setShowDeleteModal(true);
	}

	const deleteConfirmed = async () => {
		try {
			const response = await deleteClass(classId);
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				toast.success(t("Class deactivated!"));
				setClassId(null);
				setRefreshTable(true);
			}
		} catch (error) {
			toast.error(t("An error occurred while deleting the class."));
			console.log(error);
		} finally {
			setShowDeleteModal(false);
		}
	};

	const applyFilters = (data, filter) => {
		const { key, value, isActive } = filter;

		return data.filter((item) => {
			switch (key) {
				case "is_active":
					return item.is_active === value;
				case "author":
					if (isActive) {
						return item.creator === value && item.is_active === 1;
					}
					return item.creator === value;
				default:
					return true; // no specific filter matches
			}
		});
	};

	useEffect(() => {
		const getUnreviewedCount = (data) => {
			/*
			const me = `${userData.first_name} ${userData.last_name}`;
			const count = data.filter((item) => {
				return item.creator === me && item.reviewed_at === null;
			}).length;
			*/
		};

		getUnreviewedCount(tableData);
	}, [userData, tableData]);

	useEffect(() => {
		(async () => {
			const tableData = await getClasses();

			if (tableData.code === "ERR_BAD_REQUEST") {
				toast.error(t(tableData.response.data.detail));
			} else {
				setTableData(tableData);
				setRefreshTable(false);
			}
		})();
	}, [refreshTable, t]);

	return (
		<>
			<div className="view">
				<div className="header">
					<h1>{t("Classes")}</h1>
					<HelpTooltip
						text={t(
							"List of all the classes and their statuses. Teachers can define a new class and send the Class-ID to the students. The view is only visible for teachers and admin.",
						)}
					/>
				</div>

				<div className="content">
					<FilterTabs
						className="filter-tabs pt-2 mb-4 border-b"
						filters={filters}
						onChange={(index) => setFilter(filters[index])}
					/>
					<Table
						data={applyFilters(tableData, filter)}
						columns={columns}
						searchLabel={t("Search by ID or description")}
						searchColumns={["id", "description"]}
						searchGlobal={true}
						newAction={() => setShowNewClassModal(true)}
						newActionLabel={t("Create new class")}
						onRowClick={(row) => handleView(row.original.id)}
					/>
				</div>
			</div>

			{showNewClassModal && (
				<NewClassModal
					onClose={() => setShowNewClassModal(false)}
					onSubmit={(description) => {
						setDescription(description);
						createNewClass(description);
					}}
				/>
			)}

			{showEditClassModal && (
				<EditClassModal
					classId={classId}
					description={description}
					isActive={isActive}
					isPublished={isPublished}
					onClose={() => setShowEditClassModal(false)}
					onSubmit={(description, isActive, isPublished) => {
						updateClass({
							classId,
							description,
							isActive,
							isPublished,
						});
						setIsActive(isActive);
						setIsPublished(isPublished);
						setClassId(null);
						setRefreshTable(true);
					}}
				/>
			)}

			{showConfirmPublishModal && (
				<ConfirmationModal
					title={
						singleClass.is_published
							? t("Unpublish class?")
							: t("Publish class?")
					}
					message={
						singleClass.is_published
							? t("Are you sure you want to unpublish this class?")
							: t("Are you sure you want to publish this class?")
					}
					yesMessage={singleClass.is_published ? t("Unpublish") : t("Publish")}
					onClose={() => setShowConfirmPublishModal(false)}
					onSubmit={handlePublishClass}
				/>
			)}

			{showDeleteModal && (
				<ConfirmationModal
					title={t("Disable class")}
					message=<span>
						{t(
							'Are you sure you want to disable the class "{{title}}" (ID {{id}})?',
							{ title: description, id: classId },
						)}
					</span>
					yesMessage={t("Disable")}
					onClose={() => {
						setClassId(null);
						setShowDeleteModal(false);
					}}
					onSubmit={deleteConfirmed}
				/>
			)}
		</>
	);
};

Classes.displayName = "Classes";

export default Classes;
