import axiosInstance from "api/axiosInstance";

/**
 *  GET /dose-frequency
 *
 *  get all dose frequencies
 */
export const getDoseFreqs = async () => {
	return await axiosInstance
		.get("/dose-frequency")
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /dose-frequency/{dose_frequency}
 *
 *  get a dose frequency by id
 */
export const getDoseFreq = async (id) => {
	return await axiosInstance
		.get(`/dose-frequency/${id}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
