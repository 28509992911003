import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Form/Button/Button";
import Table from "components/Table/Table";

const Actions = ({ row, actions = [] }) => {
  return (
    <div className="actions-wrapper">
      {actions.map(function (action, idx) {
        return (
          <Button
            key={idx}
            className={`btn-action ${
              row.is_confirmed === true && action.name === "delete"
                ? "disabled"
                : ""
            }`}
            title={action.title}
            onClick={() => action.command(row)}
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

const UsersTable = ({
  data,
  actions,
  newAction,
  newActionLabel,
  onRowClick,
}) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () =>
      [
        {
          Header: t("E-mail"),
          accessor: "email",
        },
        {
          Header: t("First name"),
          accessor: "first_name",
        },
        {
          Header: t("Last name"),
          accessor: "last_name",
        },
        {
          Header: t("Role"),
          accessor: "user_role",
          cssClass: "text-center",
          Cell: ({ cell: { value } }) => t(value),
        },
        {
          Header: t("Status"),
          accessor: "is_blocked",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => (value ? t("Blocked") : t("Active")),
        },
        {
          Header: t("Confirmed account"),
          accessor: "is_confirmed",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) =>
            value ? (
              <i
                className="ri-check-line text-green-500"
                title={t("Confirmed")}
              ></i>
            ) : (
              t("Not confirmed")
            ),
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          disableSortBy: true,
          cssClass: "text-center min-cell-width pr-5",
          Cell: ({ row: { original } }) =>
            actions && <Actions row={original} actions={actions} />,
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  return (
    <>
      <Table
        columns={columns}
        data={data}
        searchLabel={t("Search by e-mail, first or last name")}
        searchColumns={["email", "first_name", "last_name"]}
        newAction={newAction}
        newActionLabel={newActionLabel}
        onRowClick={onRowClick}
      />
    </>
  );
};

export default UsersTable;
