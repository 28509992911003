import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Modal from "components/Modal/Modal";
import ReviewForm from "components/ReviewForm/ReviewForm";
import PatientWidget from "components/PatientWidget/PatientWidget";
import Button from "components/Form/Button/Button";
import Combo from "components/Form/Combo/Combo";
import Input from "components/Form/Input/Input";
import Textarea from "components/Form/Textarea/Textarea";

import { getPrescription } from "api/prescriptions";
import { searchDrug } from "api/drugs";
import { getDoseUnits } from "api/doseUnit";
import { getDoseFreqs } from "api/doseFreq";
import { getROAs } from "api/roa";
import { reviewPrescription } from "api/reviews";

import { useError } from "context/ErrorContext";
import { useUser } from "context/UserContext";

import "./PrescriptionModal.scss";

const PrescriptionModal = ({
	sessionId,
	caseId,
	patientId,
	prescriptionId,
	isOpen,
	onClose,
	onSubmit,
	...props
}) => {
	const { t } = useTranslation();

	const { showError } = useError();
	const { userData } = useUser();

	const [searchQuery, setSearchQuery] = useState("");

	const [prescription, setPrescription] = useState(null);
	const [refreshPrescription, setRefreshPrescription] = useState(false);
	const [dose, setDose] = useState(prescription?.dose || "");
	const [drugs, setDrugs] = useState([]);
	const [drug, setDrug] = useState(
		prescription
			? { value: prescription.drug_id, label: prescription.drug_name }
			: null,
	);
	const [units, setUnits] = useState([]);
	const [unit, setUnit] = useState(
		prescription
			? {
					value: prescription.dose_unit_id,
					label: prescription.dose_unit_description,
			  }
			: null,
	);
	const [freqs, setFreqs] = useState([]);
	const [freq, setFreq] = useState(
		prescription
			? {
					value: prescription.dose_frequency_id,
					label: prescription.dose_frequency,
			  }
			: null,
	);
	const [routes, setRoutes] = useState([]);
	const [route, setRoute] = useState(
		prescription
			? {
					value: prescription.route_of_administration_id,
					label: prescription.route_of_administration,
			  }
			: null,
	);
	const [freqNumber, setFreqNumber] = useState(
		prescription?.dose_frequency_number || "",
	);
	const [notes, setNotes] = useState(prescription?.notes || "");

	const resetForm = () => {
		setDose("");
		setDrug(null);
		setUnit(null);
		setFreq(null);
		setFreqNumber("");
		setRoute(null);
		setNotes("");
	};

	const isFormValid = () => {
		return unit && drug && freq && route && dose;
	};

	const handleSubmitReviewForm = async ({ grade, notes }) => {
		try {
			const response = await reviewPrescription({
				id: prescriptionId,
				gradeId: grade.value,
				notes: notes,
			});
			if (response === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.response.data));
			}
		} catch (error) {
			const errorMessage = error.response?.data?.detail || "An error occurred";
			toast.error(t(errorMessage));
		}
	};

	const handleSubmit = () => {
		if (!isFormValid()) {
			toast.error(t("Fill out all required fields!"));
			return;
		}

		// the destination differs if editing or creating new
		const destination = prescription
			? { prescriptionId: prescription.id }
			: { caseId: caseId };

		onSubmit({
			...destination,
			drugId: drug.value,
			ROAId: route.value,
			doseUnitId: unit.value,
			doseFreqId: freq.value,
			dose: dose,
			doseFreqNumber: freqNumber,
			notes: notes,
		});
		resetForm();
		onClose();
	};

	const getModalTitle = (prescription) => {
		if (!prescription) return t("Add prescription");
		if (prescription && prescription.status_id !== 1)
			return t("Review prescription");
		if (prescription && prescription.status_id === 1)
			return t("Edit prescription");
	};

	/* get the prescription */

	useEffect(() => {
		(async () => {
			if (prescriptionId) {
				console.log("refreshing prescription...");
				const data = await getPrescription(prescriptionId);
				if (data === "ERR_BAD_REQUEST") {
					toast.error(t(data.response.response.data));
					console.log(data);
				} else {
					setPrescription(data);
					setDrug({ value: data.drug_id, label: data.drug_name });
					setFreqNumber(data.dose_frequency_number);
					setFreq({
						value: data.dose_frequency_id,
						label: data.dose_frequency,
					});
					setDose(data.dose);
					setUnit({
						value: data.dose_unit_id,
						label: data.dose_unit_description,
					});
					setRoute({
						value: data.route_of_administration_id,
						label: data.route_of_administration,
					});
					setNotes(data.notes);
					setRefreshPrescription(false);
				}
			}
		})();
	}, [prescriptionId, showError, refreshPrescription, t]);

	// populating the drug list
	useEffect(() => {
		(async () => {
			const data = await searchDrug(searchQuery ? searchQuery : "a");
			if (data === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.response.data));
				console.log(data);
			} else {
				if (Array.isArray(data))
					setDrugs(
						data.map((item) => ({
							value: item.id,
							label: item.name,
						})),
					);
				else setDrugs([]);
			}
		})();
	}, [searchQuery, showError, t]);

	// populating the dose frequency list
	useEffect(() => {
		(async () => {
			const data = await getDoseFreqs();
			if (data === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.response.data));
				console.log(data);
			} else {
				setFreqs(
					data.map((item) => ({
						value: item.id,
						label: `${item.description} (${item.code})`,
					})),
				);
			}
		})();
	}, [t]);

	// populating the dose unit list
	useEffect(() => {
		(async () => {
			const data = await getDoseUnits();
			if (data === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.response.data));
				console.log(data);
			} else {
				setUnits(
					data.map((item) => {
						return {
							value: item.id,
							label: `${item.description} (${item.code})`,
						};
					}),
				);
			}
		})();
	}, [t]);

	// populating the route of administration list
	useEffect(() => {
		(async () => {
			const data = await getROAs();
			if (data === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.response.data));
				console.log(data);
			} else {
				setRoutes(
					data.map((item) => ({
						value: item.id,
						label: item.description,
					})),
				);
			}
		})();
	}, [t]);

	return (
		<Modal
			title={getModalTitle(prescription)}
			isOpen={isOpen}
			onClose={onClose}
			onSubmit={onSubmit}
			className="prescription-modal"
		>
			<div className="flex gap-8 h-full w-full">
				<div className="flex w-3/4 flex-col gap-4">
					<h3 className="">{t("Patient file")}</h3>
					<PatientWidget
						patientId={patientId}
						className="mb-8"
						hintText={t(
							"First you need to chose a patient, and after that you can start prescribing.",
						)}
						readOnly={true}
					/>
					<h3>{t("Prescription")}</h3>
					<form className="w-full flex flex-col">
						<Combo
							id="drug"
							label={t("Product")}
							options={drugs}
							value={drug}
							onChange={setDrug}
							query={searchQuery}
							setQuery={setSearchQuery}
							autoFocus={true}
							readOnly={prescription && prescription.status_id !== 1}
						/>
						{/*error === "drug" ? (
									<span>The product is a requierd field</span>
								) : (
									""
								)*/}
						<div className="flex max-w-full my-5 gap-4">
							<Input
								id="freqNumber"
								className="w-40"
								label={t("Frequency")}
								value={freqNumber}
								onChange={(e) => setFreqNumber(e.target.value)}
								readOnly={prescription && prescription.status_id !== 1}
							/>
							<Combo
								id="freq"
								className="mr-4"
								label={t("Time unit")}
								options={freqs}
								value={freq}
								onChange={setFreq}
								readOnly={prescription && prescription.status_id !== 1}
							/>
							<Input
								id="dose"
								className="w-40"
								label={t("Dose")}
								value={dose}
								onChange={(e) => setDose(e.target.value)}
								readOnly={prescription && prescription.status_id !== 1}
							/>
							<Combo
								id="unit"
								className="mr-4"
								label={t("Unit")}
								options={units}
								value={unit}
								onChange={setUnit}
								readOnly={prescription && prescription.status_id !== 1}
							/>
							<Combo
								id="route"
								label={t("Route")}
								options={routes}
								value={route}
								onChange={setRoute}
								readOnly={prescription && prescription.status_id !== 1}
							/>
						</div>
						<Textarea
							id="notes"
							label={t("Notes (optional)")}
							value={notes}
							rows={5}
							onChange={(e) => setNotes(e.target.value)}
							readOnly={prescription && prescription.status_id !== 1}
						/>
					</form>
				</div>
				<div className="aside flex flex-col">
					{prescription && (
						<ReviewForm
							target={prescription}
							userData={userData}
							readOnly={
								!(
									(userData.user_role === "Teacher")
									//&&
									//session.status === "Submitted"
								)
							}
							buttonCaption={t("Review prescription")}
							onClose={() => setRefreshPrescription(true)}
							onSubmit={handleSubmitReviewForm}
						/>
					)}
					<div className="flex flex-col justify-center mt-auto">
						<Button
							className={`btn-primary w-min self-end ${
								prescription && prescription.status_id !== 1 && "disabled"
							}`}
							onClick={handleSubmit}
						>
							{t("Save & Close prescription")}
						</Button>
						{prescription && prescription.status_id !== 1 && (
							<Button className="btn-primary w-full mt-5" onClick={onClose}>
								{t("Close prescription")}
							</Button>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default PrescriptionModal;
