import axiosInstance from "api/axiosInstance";

/**
 *  GET /courses
 *
 *  get all courses
 */
export const getCourses = async () => {
	return await axiosInstance
		.get("/course")
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /course/{course_id}
 *
 *  get a course level by id
 */
export const getCourse = async (id) => {
	return await axiosInstance
		.get(`/course/${id}`)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /course
 *
 *  create a new course level
 */
export const createCourse = async ({ name, description }) => {
	const data = {
		name,
		description,
	};

	try {
		const response = await axiosInstance.post("/course", data);
		return response;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /course/{course_id}
 *
 *  update a course level
 */
export const updateCourse = async ({ id, name, description }) => {
	const data = {
		name,
		description,
	};

	try {
		const response = await axiosInstance.put(`/course/${id}`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /course/{course_id}
 *
 *  delete a course level
 */
export const deleteCourse = async (id) => {
	try {
		const response = await axiosInstance.delete(`/course/${id}`);
		return response;
	} catch (error) {
		throw error;
	}
};
