import { Switch } from "@headlessui/react";

const Toggle = ({
  id,
  className,
  label,
  checked,
  onChange: handleChange,
  ...rest
}) => {
  const classes = ["toggle-container flex items-center"];

  if (className) classes.push(className);

  return (
    <Switch.Group>
      <div className={classes.join(" ")}>
        <Switch
          className={`relative inline-flex h-6 w-11 shrink-0 items-center rounded-full ${
            checked ? "bg-primary" : "bg-gray-300"
          }`}
          checked={checked}
          onChange={(value) => {
            handleChange && handleChange(value);
          }}
        >
          <span
            className={`inline-block h-4 w-4 rounded-full bg-white transition-transform transform duration-600 ${
              checked ? "translate-x-6" : "translate-x-1"
            }`}
          />
        </Switch>
        <Switch.Label className="ml-4">{label}</Switch.Label>
      </div>
    </Switch.Group>
  );
};

export default Toggle;
