import React, { useEffect, useState } from "react";
import { getPrescriptions } from "api/prescriptions";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  createPrescription,
  updatePrescription,
  deletePrescription,
} from "api/prescriptions";

import PrescriptionsTable from "components/PrescriptionsTable/PrescriptionsTable";
import PrescriptionModal from "components/PrescriptionModal/PrescriptionModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import { useUser } from "context/UserContext";

const PrescriptionsWidget = ({ caseId, patientId = 0, readOnly = false }) => {
  const { t } = useTranslation();
  const { canI } = useUser();
  const [prescriptions, setPrescriptions] = useState([]);
  const [prescription, setPrescription] = useState(null);
  const [prescriptionId, setPrescriptionId] = useState(null);
  const [refreshPrescriptions, setRefreshPrescriptions] = useState(true);
  const [showPrescriptionModal, setShowPrescriptionModal] = useState(false);
  const [showDeletePrescriptionModal, setShowDeletePrescriptionModal] =
    useState(false);

  /**
   * prescriptions table - actions
   *
   *  these are the actions allowed depending on the role
   *  the actions allowed depending on the status of each item
   *  are defined in PrescriptionsTable
   */

  const rowActions = [];

  if (canI("delete", "Prescription"))
    rowActions.push({
      name: "delete",
      icon: "ri-delete-bin-line",
      command: handleDelete,
      title: t("Delete prescription"),
    });

  /**
   * PRESCRIPTION HANDLERS
   */

  const handleSubmitPrescription = async (prescription) => {
    console.log(prescription);
    try {
      // update a prescription
      if (prescription.prescriptionId) {
        const response = await updatePrescription({
          id: prescription.prescriptionId,
          drugId: prescription?.drugId,
          ROAId: prescription?.ROAId,
          doseUnitId: prescription?.doseUnitId,
          doseFreqId: prescription?.doseFreqId,
          dose: prescription?.dose,
          doseFreqNumber: prescription?.doseFreqNumber,
          notes: prescription?.notes,
        });

        if (response.status === 200 || response.status === 201) {
          toast.success(t("Prescription saved!"));
          setRefreshPrescriptions(true);
        } else {
          console.error("Unexpected response:", response);
        }
      } else {
        // create a new prescription
        const response = await createPrescription({
          TPId: caseId,
          drugId: prescription?.drugId,
          ROAId: prescription?.ROAId,
          doseUnitId: prescription?.doseUnitId,
          doseFreqId: prescription?.doseFreqId,
          dose: prescription?.dose,
          doseFreqNumber: prescription?.doseFreqNumber,
          notes: prescription?.notes,
        });

        if (response.status === 200 || response.status === 201) {
          toast.success(t("Prescription saved!"));
          setRefreshPrescriptions(true);
        } else {
          console.error("Unexpected response:", response);
        }
      }
    } catch (error) {
      const errorMessage = error.response?.data?.detail || "An error occurred";
      toast.error(t(errorMessage));
      console.error("An error occurred:", error);
    }
  };

  function handleDelete(row) {
    console.log(row);
    setPrescription(row);
    setShowDeletePrescriptionModal(true);
  }

  const handleDeleteConfirmed = async () => {
    setShowDeletePrescriptionModal(false);
    const response = await deletePrescription(prescription.id);
    if (response.code === "ERR_BAD_REQUEST") {
      toast.error(t(response.response.data.detail));
      console.log(response);
    } else {
      toast.success(t("Prescription deleted!"));
      setRefreshPrescriptions(true);
    }
  };

  // load prescriptions

  useEffect(() => {
    if (!refreshPrescriptions) return;

    const fetchPrescriptions = async () => {
      if (!caseId) {
        setPrescriptions([]);
        return;
      }

      try {
        const data = await getPrescriptions(caseId);
        setPrescriptions(data);
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setRefreshPrescriptions(false);
      }
    };

    fetchPrescriptions();
  }, [caseId, refreshPrescriptions]);

  return (
    <>
      <div className="prescriptions-widget">
        <PrescriptionsTable
          data={prescriptions}
          actions={rowActions}
          showSearch={false}
          readOnly={readOnly}
          newAction={() => {
            setPrescriptionId(null);
            setShowPrescriptionModal(true);
          }}
          newActionLabel={t("Add prescription")}
          onRowClick={(row) => {
            setPrescriptionId(row.original.id);
            setShowPrescriptionModal(true);
          }}
        />
      </div>

      {showPrescriptionModal && (
        <PrescriptionModal
          patientId={patientId}
          prescriptionId={prescriptionId}
          onSubmit={handleSubmitPrescription}
          onClose={() => setShowPrescriptionModal(false)}
        />
      )}

      {showDeletePrescriptionModal && (
        <ConfirmationModal
          title={t("Delete prescription?")}
          message={`${t("Are you sure you want to delete the prescription")} ${
            prescription.drug_name
          }?`}
          yesMessage={t("Delete")}
          onClose={() => setShowDeletePrescriptionModal(false)}
          onSubmit={handleDeleteConfirmed}
        />
      )}
    </>
  );
};

export default PrescriptionsWidget;
