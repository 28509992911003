import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";

import { getPatient } from "api/patients";

import { addLeadingZeros } from "utils/utils";

import "./PatientWidget.scss";

const PatientWidget = ({
  patientId,
  hintText = "",
  readOnly = false,
  onSubmit = null,
  className = "",
}) => {
  const { t } = useTranslation();

  const [patient, setPatient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const historyBadges = [];

  const classes = [
    "patient-widget min-h-[9rem] px-4 py-2 flex flex-col justify-center relative bg-slate-100 border rounded font-sm",
  ];
  if (className) classes.push(className);

  useEffect(() => {
    if (!patientId) {
      setPatient(null);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const loadPatient = async () => {
      try {
        const data = await getPatient(patientId);
        setPatient(data);
      } catch (error) {
        console.log("Error: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    loadPatient();
  }, [patientId]);

  if (patient?.patient_history)
    patient["patient_history"].forEach((item, idx) => {
      if (item.description)
        historyBadges.push(
          <Badge key={idx} className="bg-slate-400" value={item.description} />,
        );
    });
  if (historyBadges.length === 0) historyBadges.push("-");

  return (
    <div className={classes.join(" ")}>
      {isLoading && <div className="loading-spinner">loading</div>}

      {_.isEmpty(patient) && !readOnly && !isLoading && (
        <div className="no-patient flex flex-col center">
          {hintText && <p className="mb-2 text-sm">{hintText}</p>}
          <Button className="btn-primary" onClick={onSubmit}>
            {t("Select patient")}
          </Button>
        </div>
      )}

      {_.isEmpty(patient) && readOnly && (
        <div className="flex items-center mb-2">
          <p className="whitespace-pre-wrap">
            {t(
              "No patient selected. You can still continue prescribing even without a patient file.\nIf you want to select a patient, close this modal and select one in the case.",
            )}
          </p>
        </div>
      )}

      {!_.isEmpty(patient) && (
        <>
          {!readOnly && (
            <div className="absolute right-2 top-2">
              <Button className="btn-action" onClick={onSubmit}>
                <i className="ri-pencil-line"></i>
              </Button>
            </div>
          )}
          <div className="grid grid-rows-4 grid-flow-col gap-x-4">
            <div className="flex items-center">
              <label>{t("Patient ID")}</label>
              <p>{addLeadingZeros(patient?.patient.id, 5)}</p>
            </div>
            <div className="flex items-center">
              <label>{t("First name")}</label>
              <p>{patient?.patient.first_name}</p>
            </div>
            <div className="flex items-center">
              <label>{t("Last name")}</label>
              <p>{patient?.patient.last_name}</p>
            </div>
            <div className="flex items-center">
              <label>{t("Date of birth")}</label>
              <p>{patient?.patient.birth_date}</p>
            </div>
            <div className="flex items-center">
              <label>{t("Age")}</label>
              <p>{patient?.patient.age}</p>
            </div>
            <div className="flex items-center">
              <label>{t("Sex")}</label>
              <p>{t(patient?.patient.gender)}</p>
            </div>
            <div className="flex items-center">
              <label>{t("Body weight")}</label>
              <p>
                {t("number", { val: patient?.patient.body_weight }) || "-"}{" "}
                {t(patient?.patient.body_unit)}
              </p>
            </div>
            <div className="flex items-center">
              <label>{t("eGFR")}</label>
              <p>
                {t("number", { val: patient?.patient.kidney_function }) || "-"}{" "}
                {patient?.patient.kidney_function && t("ml/min/1.73m²")}
              </p>
            </div>
            <div className="flex items-center mb-2 whitespace-nowrap">
              <label>{t("Medical history")}</label>
            </div>
            <div className="history flex self-start mb-2 text-sm">
              {historyBadges}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientWidget;
