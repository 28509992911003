export const formatDate = (inputDate) => {
  const date = new Date(inputDate);

  if (date.getTime() === new Date("1970-01-01").getTime()) return "-";

  if (isNaN(date)) {
    return "Invalid date format";
    // throw new Error("Invalid date");
  }

  var dateStr = date.toLocaleString("nl-NL", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  dateStr = dateStr.replace(",", "\r\n");

  return dateStr;
};

export const getDate = (inputDate) => {
  const date = new Date(inputDate);

  if (date.getTime() === new Date("1970-01-01").getTime()) return "-";

  if (isNaN(date)) {
    return "Invalid date format";
    // throw new Error("Invalid date");
  }

  var dateStr = date.toLocaleString(["nl-NL"], {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });

  return dateStr;
};

export const addLeadingZeros = (number, zeros = 5) => {
  number = String(number);
  return number.padStart(zeros, "0");
};

export const canAddSession = (userData) => {
  return userData.user_role === "Student";
};

export const isStudent = (userData) => {
  return userData.user_role === "Student";
};

export const isTeacher = (userData) => {
  return userData.user_role === "Teacher";
};

export const isDraft = (session) => {
  return session.status === "Draft";
};

export const parseJwt = (token) => {
  if (!token || typeof token !== "string" || token.split(".").length < 3) {
    console.error("Invalid JWT token");
    return null;
  }

  try {
    const base64 = token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(atob(base64));
  } catch (error) {
    console.error("Error parsing JWT:", error);
    return null;
  }
};
