import { useEffect } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "context/AuthContext";
import { useUser, CanIView } from "context/UserContext";

import Login from "views/Login/Login";
import Logout from "views/Logout/Logout";
import Register from "views/Register/Register";
import ValidateCode from "views/Register/ValidateCode";
import Reset from "views/ResetPassword/Reset";
import ResetCode from "views/ResetPassword/ResetCode";
import ResetPassword from "views/ResetPassword/ResetPassword";
import Page404 from "views/404/Page404";

import LoginLayout from "layouts/LoginLayout/LoginLayout";
import MainLayout from "layouts/MainLayout/MainLayout";
import Dashboard from "views/Dashboard/Dashboard";
import Classes from "views/Classes/Classes";
import SingleClass from "views/SingleClass/SingleClass";
import ReviewStory from "views/ReviewStory/ReviewStory";
import Stories from "views/Stories/Stories";
import Patients from "views/Patients/Patients";
import Users from "views/Users/Users";
import Sessions from "views/Sessions/Sessions";
import SingleSession from "views/SingleSession/SingleSession";
import SingleTreatmentPlan from "views/SingleTreatmentPlan/SingleTreatmentPlan";
import Profile from "views/Profile/Profile";
import Settings from "views/Settings/Settings";
import TermsAndConditions from "views/TermsAndConditions/TermsAndConditions";

const PublicRoutes = () => {
  const { isAuthenticated, isAuthLoading } = useAuth();
  const { isUserLoading } = useUser();

  // do nothing while loading
  if (isAuthLoading || isUserLoading) return null;

  // redirect to dashboard if user is already authenticated
  return isAuthenticated ? <Navigate to="/dashboard" /> : <Outlet />;
};

const PrivateRoutes = () => {
  const { isAuthenticated, isAuthLoading } = useAuth();
  const { isUserLoading } = useUser();

  // do nothing while loading
  if (isAuthLoading || isUserLoading) return null;

  // redirect to login if user is not authenticated
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

const App = () => {
  const { t } = useTranslation();
  const { setAuthData, isAuthLoading } = useAuth();
  const { userData, isUserLoading, canI } = useUser();

  useEffect(() => {
    if (!isAuthLoading && !isUserLoading) {
      console.log("userData", userData);
      if (userData === null) {
        console.log("userData is null. removing authData");
        setAuthData(null);
        localStorage.removeItem("authData");
      }
    }
  });

  return (
    <>
      <Routes>
        {/* public routes */}
        <Route element={<PublicRoutes />}>
          <Route element={<LoginLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/validate-code" element={<ValidateCode />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/reset-code" element={<ResetCode />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Route>
        </Route>
        {/* private routes */}
        <Route element={<PrivateRoutes />}>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route
              path="/login"
              element={<Navigate to="/dashboard" replace />}
            />
            <Route
              path="/register"
              element={<Navigate to="/dashboard" replace />}
            />
            <Route
              path="/validate-code"
              element={<Navigate to="/dashboard" replace />}
            />
            <Route
              path="/reset"
              element={<Navigate to="/dashboard" replace />}
            />
            <Route
              path="/reset-code"
              element={<Navigate to="/dashboard" replace />}
            />
            <Route
              path="/reset-password"
              element={<Navigate to="/dashboard" replace />}
            />
            <Route path="/logout" element={<Logout />} />

            <Route
              index
              path="/dashboard"
              element={<CanIView component={<Dashboard />} />}
            />
            <Route
              path="/classes"
              element={<CanIView component={<Classes />} />}
            />
            <Route
              path="/classes/:classId"
              element={<CanIView component={<SingleClass />} />}
            />
            <Route
              path="/classes/:classId/review/:storyId"
              element={<CanIView component={<ReviewStory />} />}
            />
            <Route
              path="/stories"
              element={<CanIView component={<Stories />} />}
            />
            <Route
              path="/patients"
              element={<CanIView component={<Patients />} />}
            />
            <Route path="/users" element={<CanIView component={<Users />} />} />
            <Route
              path="/sessions"
              element={<CanIView component={<Sessions />} />}
            />
            <Route
              path="/session/:sessionId"
              element={
                <CanIView
                  component={
                    <SingleSession
                      readOnly={!canI("create", "SingleTreatmentPlan")}
                    />
                  }
                />
              }
            />
            <Route
              path="/session/:sessionId/review"
              element={
                <CanIView
                  component={
                    <SingleSession
                      readOnly={!canI("create", "SingleTreatmentPlan")}
                      showReview={true}
                    />
                  }
                />
              }
            />
            <Route
              path="/session/:sessionId/treatment"
              element={<CanIView component={<SingleTreatmentPlan />} />}
            />
            <Route
              path="/session/:sessionId/treatment/:treatmentPlanId"
              element={
                <CanIView
                  component={
                    <SingleTreatmentPlan
                      readOnly={!canI("create", "Prescription")}
                    />
                  }
                />
              }
            />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/settings"
              element={<CanIView component={<Settings />} />}
            />
            <Route path="*" element={<Page404 />} />
          </Route>
        </Route>
      </Routes>

      {(isAuthLoading || isUserLoading) && (
        <div className="h-screen w-full flex center">
          <div>{t("Loading...")}</div>
        </div>
      )}
    </>
  );
};

export default App;
