import axiosInstance from "api/axiosInstance";

/**
 *  GET /unitType
 *
 *  get all unit types
 */
export const getUnitTypes = async () => {
	return await axiosInstance
		.get("/unitType")
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /unitType/{unit_type_id}/unit
 *
 *  get all units from a unit type
 */
export const getUnits = async ({ unitTypeId }) => {
	return await axiosInstance
		.get(`/unitType/${unitTypeId}/unit`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
