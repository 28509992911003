import axiosInstance from "api/axiosInstance";

/**
 *  GET /drug/search/{query}
 *
 *  search drug by keyword
 */
export const searchDrug = async (keyword) => {
	return await axiosInstance
		.get(`/drug/search/${keyword}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /drug/{drug_id}
 *
 *  get drug by id
 */
export const getDrug = async ({ token, drugId }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/drug/${drugId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
